import pennyappeal from '../../../images/pennyappeal.png';
import PS_logo from '../../../images/PS_43_LOGO.png';


import styles from '../styles/Partners.module.css';

const Partners = () => {
  return (
    <div className={styles['partners-container']}>
      <h3>
        Our Partners
        <span />
      </h3>
      <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed aliquyam erat, sed diam voluptua.</p>
      <div className={styles['partners-logos']}>
        <img src={pennyappeal} alt="company 1 logo" />
        <img src={PS_logo} alt="company 2 logo" />
      </div>
    </div>
  );
};

export default Partners;
