import React, { useState } from 'react';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

export default function JoinModal({ open, handleClose, setSnackOpen, setSnackMessage }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [email, setEmail] = useState('');

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '20px',
    boxShadow: 24,
    p: 4,
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  };

  async function submitToMailingList() {
    const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ firstName, lastName, email }),
    };

    fetch(`${process.env.REACT_APP_SWC_API_URL}/external/mailingList`, requestOptions)
      .then(async (response) => {
        if (response.ok) {
            setSnackMessage("Thank you for subscribing!")
            setSnackOpen(true);
            handleClose()
        } else {
            setSnackMessage("You're already part of our mailing list")
            setSnackOpen(true);
            handleClose()
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } 

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h4">
          Subscribe
        </Typography>

        <Typography id="modal-modal-description" variant="h7">
          Enter your information below to get notified as soon as courses become available!
        </Typography>

        <TextField
          id="outlined-controlled"
          label="First Name"
          fullWidth
          margin="dense"
          value={firstName}
          onChange={(event) => {
            setFirstName(event.target.value);
          }}
        />

        <TextField
          id="outlined-controlled"
          label="Last Name"
          fullWidth
          margin="dense"
          value={lastName}
          onChange={(event) => {
            setlastName(event.target.value);
          }}
        />

        <TextField
          id="outlined-controlled"
          label="Email"
          fullWidth
          margin="dense"
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        />

        <Button 
            color="success" 
            variant="contained"
            sx={{ m: 1 }}
            onClick={submitToMailingList}
        >
            SUBSCRIBE
        </Button>
      </Box>
    </Modal>
  );
}
