import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Header from '../shared/header/Header';
import globalNavigation from '../../config/globalNavigation';
import { AppContext } from '../../contexts/AppContext';

import styles from './styles/Groups.module.css';

function Groups() {
  const { jwt, groups, groupDetails } = useContext(AppContext);
  const [jwtValue, setJwtValue] = jwt;
  const [groupsValue, setGroupsValue] = groups;
  const [groupDetailsValue, setGroupDetailsValue] = groupDetails;

  const history = useHistory();

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': jwtValue,
      },
    };
    if (!groupsValue) {
      fetch(`${process.env.REACT_APP_SWC_API_URL}/group`, requestOptions)
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem('swc-access-token');
          localStorage.removeItem('swc-user-email');
          setJwtValue('');
          history.push('/');
        }
        return response.json();
      })
      .then((data) => {
        setGroups(data.groups);
        setGroupDetails(data.groups);
      })
      .catch((error) => {
        console.log(error);
      });
    } else {
      setGroupsValue(groupsValue);
      setGroupDetailsValue(groupDetailsValue);
    }
  }, []);

  function setGroups(data) {
    const groupsInfo = data.map((groupObj) => {
      return {
        id: groupObj._id,
        _id: groupObj._id,
        name: groupObj.name,
        lastLessonViewedId: groupObj.lastLessonViewedId ? groupObj.lastLessonViewedId : '',
        course: groupObj.course,
      };
    });

    setGroupsValue(groupsInfo);
  }

  function setGroupDetails(data) {
    const groupsDetails = data.map((groupObj) => ({
      lastLessonViewedId: groupObj.lastLessonViewedId ? groupObj.lastLessonViewedId : '',
      _id: groupObj._id,
      name: groupObj.name || groupObj.course.name,
      lessons: groupObj.lessons || groupObj.course.lessons,
      description: groupObj.description || groupObj.course.description,
    }));
    setGroupDetailsValue(groupsDetails);
  }

  function displayGroups() {
    if (!groupsValue) return;

    return groupsValue.map((group) => {
      return (
        <div
          key={group.id}
          onClick={() => history.push('/groups/' + group.id)}
          className={styles.courses__course__detail}
        >
          <p>{group.name}</p>
        </div>
      );
    });
  }

  const doodleStyles = `
    @grid: 1 / 100vw;
    @content: @svg(
	viewBox: 0 0 (16 / (1 / 100vw)) (16 / (1 / 100vh));
	stroke: #fff;
	stroke-opacity: .2;
	stroke-width: .04;
	stroke-linecap: round;
	line*16x16 {
	    draw: @r(2s);
	    x1, y1, x2, y2: @p(
		@nx(-1) @ny(-1) @nx @ny,
		@nx @ny(-1) @nx(-1) @ny,
		@nx @ny(-1) @nx @ny
	    );
	}
    );`;

  return (
    <div className={styles.courses}>
      <css-doodle class={styles.doodle}>
        <style>{doodleStyles}</style>
      </css-doodle>
      <div className={styles['courses--content']}>
        <Header menuItems={globalNavigation.groups} />
        <div className={styles['courses--courselist']}>{displayGroups()}</div>
      </div>
    </div>
  );
}

export default Groups;
