import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import styles from './styles/Footer.module.css';

const Footer = () => {
  return (
    <div className={styles['footer']}>
      <div className={styles['call-to-action']}>
        <h5>Students Who Code</h5>
        <h6>Bring Your Ideas To Life!</h6>
        <div className={styles['cta-buttons']}>
          <button className={styles['join-button']}>Join Today</button>
          <button className={styles['about-button']}>About Us</button>
        </div>
      </div>
      <div className={styles['contact-us']}>
        <h5>Contact us</h5>
        <div className={styles['contact-icons']}>
          <a href="#">
            <FontAwesomeIcon icon={faEnvelope} size="2x" />
          </a>
          <a href="https://www.linkedin.com/company/studentswhocode" target="_blank">
            <FontAwesomeIcon icon={faLinkedin} size="2x" />
          </a>
          <a href="https://www.instagram.com/studentswhocode" target="_blank">
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
