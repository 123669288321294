import React, { useContext, useEffect, useState, useRef } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell as faSolidBell, faCheck, faComment } from '@fortawesome/free-solid-svg-icons';
import { faBell as faReuglarBell } from '@fortawesome/free-regular-svg-icons';
import styles from '../styles/NotificationCenter.module.css';
import { useHistory } from 'react-router-dom';

function NotificationCenter() {
  const [isOpen, setIsOpen] = useState(false);
  const changeIsOpen = () => {
    setIsOpen(!isOpen);
  };
  const [unseenNotifications, setUnseenNotifications] = useState([]);
  const [seenNotifications, setSeenNotifications] = useState([]);
  const [showReadNotifications, setShowReadNotifications] = useState(false);
  const ref = useRef(null);
  const isMountedRef = useRef(false);

  const { jwt } = useContext(AppContext);
  const [jwtValue, setJwtValue] = jwt;

  const history = useHistory();

  function timeSince(date) {
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + ' years ago';
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + ' months ago';
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + ' days ago';
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + ' hours ago';
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + ' minutes ago';
    }
    return Math.floor(seconds) + ' seconds ago';
  }

  function shortenString(string) {
    if (string.length > 55) {
      return string.substring(0, 52) + '...';
    }
    return string;
  }

  function recentFirst(a, b) {
    if (a.createdAt < b.createdAt) return 1;
    if (a.createdAt > b.createdAt) return -1;
    return 0;
  }

  function handleMarkAsRead(notificationId) {
    const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json', 'x-access-token': jwtValue },
      body: JSON.stringify({
        notificationIds: notificationId,
      }),
    };
    fetch(`${process.env.REACT_APP_SWC_API_URL}/notification`, requestOptions)
      .then((res) => {
        if (res.status === 200) {
          if (isMountedRef.current) {
            const notificationIndex = unseenNotifications.findIndex(
              (unseenNotification) => unseenNotification._id === notificationId[0]
            );
            const [notification] = unseenNotifications.splice(notificationIndex, 1);
            setUnseenNotifications([...unseenNotifications]);
            setSeenNotifications(seenNotifications.concat(notification).sort(recentFirst));
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleMarkAllAsRead() {
    const unseenNotificationIds = unseenNotifications.map((unseenNotification) => unseenNotification._id);
    const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json', 'x-access-token': jwtValue },
      body: JSON.stringify({
        notificationIds: unseenNotificationIds,
      }),
    };
    fetch(`${process.env.REACT_APP_SWC_API_URL}/notification`, requestOptions)
      .then((res) => {
        if (res.status === 200) {
          setSeenNotifications(seenNotifications.concat(unseenNotifications).sort(recentFirst));
          setUnseenNotifications([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    isMountedRef.current = true;
    document.addEventListener('click', handleClickOutside);
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'x-access-token': jwtValue },
    };
    const fetchNotifications = () => {
      fetch(`${process.env.REACT_APP_SWC_API_URL}/notification`, requestOptions)
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (isMountedRef.current) {
            setUnseenNotifications(data.unseen.sort(recentFirst));
            setSeenNotifications(data.seen.sort(recentFirst));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchNotifications();
    const intervalId = setInterval(fetchNotifications, 30000);
    return () => {
      clearInterval(intervalId);
      document.removeEventListener('click', handleClickOutside);
      isMountedRef.current = false;
    };
  }, [jwtValue]);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  function displayUnseenNotifications() {
    if (!unseenNotifications) return;
    return unseenNotifications.map((unseenNotification) => {
      return (
        <div
          key={unseenNotification._id}
          className={styles.notification}
          onClick={() => {
            handleMarkAsRead([unseenNotification._id]);
            history.push(
              `/groups/${unseenNotification.groupId}/${unseenNotification.lessonId._id}/${unseenNotification.commentId}`
            );
          }}
        >
          <div className={styles['notification-header']}>
            <span className={styles.replier}>{`${unseenNotification.replier} replied`}</span>
            <span className={styles.time}>{`${timeSince(new Date(unseenNotification.createdAt))}`}</span>
          </div>
          <div className={styles.content}>
            <FontAwesomeIcon icon={faComment} className={styles.comment} />
            <span>{` ${shortenString(unseenNotification.content)}`}</span>
          </div>
          <div className={styles['notification-footer']}>
            <div className={styles['course-and-lesson-name']}>
              {`${unseenNotification.lessonId.courseId.name} | ${unseenNotification.lessonId.name}`}
            </div>
            <span
              className={styles['mark-as-read']}
              onClick={(event) => {
                event.stopPropagation();
                handleMarkAsRead([unseenNotification._id]);
              }}
            >
              Mark as read
            </span>
          </div>
        </div>
      );
    });
  }

  function displaySeenNotifications() {
    if (!seenNotifications) return;
    return seenNotifications.map((seenNotification) => {
      return (
        <div
          key={seenNotification._id}
          className={`${styles.notification} ${styles.read}`}
          onClick={() => {
            handleMarkAsRead([seenNotification._id]);
            history.push(
              `/groups/${seenNotification.groupId}/${seenNotification.lessonId._id}/${seenNotification.commentId}`
            );
          }}
        >
          <div className={styles['notification-header']}>
            <span className={styles.replier}>{`${seenNotification.replier} replied`}</span>
            <span className={styles.time}>{`${timeSince(new Date(seenNotification.createdAt))}`}</span>
          </div>
          <div className={styles.content}>
            <FontAwesomeIcon icon={faComment} className={styles.comment} />
            <span>{` ${shortenString(seenNotification.content)}`}</span>
          </div>
          <div className={styles['notification-footer']}>
            <div className={styles['course-and-lesson-name']}>
              {`${seenNotification.lessonId.courseId.name} | ${seenNotification.lessonId.name}`}
            </div>
            <></>
          </div>
        </div>
      );
    });
  }

  function shortenBadge(n) {
    if (n > 99) return '99+';
    else return n;
  }

  return (
    <div className={styles.container} ref={ref}>
      <div onClick={changeIsOpen} className={isOpen ? `${styles.bell} ${styles.active}` : `${styles.bell}`}>
        <FontAwesomeIcon icon={isOpen ? faSolidBell : faReuglarBell}></FontAwesomeIcon>
        {unseenNotifications.length > 0 && (
          <span className={styles.badge}>{shortenBadge(unseenNotifications.length)}</span>
        )}
      </div>
      {isOpen && (
        <div className={styles['notification-center']}>
          <div className={styles.triangle}></div>
          <div className={styles['top-bar']}>
            <span className={styles.title}>Notifications</span>
            <></>
            {unseenNotifications.length > 0 && (
              <span className={styles['mark-all-as-read']} onClick={handleMarkAllAsRead}>
                Mark all as read
              </span>
            )}
          </div>
          {unseenNotifications.length + seenNotifications.length === 0 && (
            <span className={styles.empty}>
              <FontAwesomeIcon icon={faCheck} className={styles.check} />
              <span>You have no notifications</span>
            </span>
          )}
          <div className={styles.notifications}>
            {!showReadNotifications && unseenNotifications.length === 0 && seenNotifications.length > 0 && (
              <span className={styles.empty}>
                <FontAwesomeIcon icon={faCheck} className={styles.check} />
                <span>You have no unread notifications</span>
              </span>
            )}
            {displayUnseenNotifications()}
            {showReadNotifications && displaySeenNotifications()}
          </div>
          {seenNotifications.length > 0 && (
            <div className={styles['bottom-bar']}>
              <span onClick={() => setShowReadNotifications(!showReadNotifications)} className={styles['show-hide']}>
                {showReadNotifications ? 'Hide recent read notifications' : 'Show recent read notifications'}
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default NotificationCenter;
