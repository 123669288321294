import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';

import Reply from './Reply';

import styles from '../styles/Comments.module.css';

function ReplyList({ replies, updateComment, parentCommentId, showReplyList, changeShowReplyList }) {
  let { replyId } = useParams();
  const [showAllReplies, setShowAllReplies] = useState(false);
  const chosenReply = useRef(null);

  useEffect(() => {
    if (chosenReply && chosenReply.current)
      chosenReply.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
  }, [chosenReply]);

  const handleToggleReplies = () => setShowAllReplies(prev => !prev);

  return (
    <div className={`${styles['expand--reply']} ${showReplyList ? styles['active'] : ''}`}>
      <div className={styles['show--reply']} onClick={changeShowReplyList}>
        <FontAwesomeIcon icon={showReplyList ? faCaretUp : faCaretDown} />
        &nbsp;
        {replies.length > 0 && (
          <button
            className={styles['show--reply']}
            onClick={handleToggleReplies}
          >
            {showAllReplies
              ? `Hide ${replies.length} ${replies.length > 1 ? 'replies' : 'reply'}`
              : `Show ${replies.length} ${replies.length > 1 ? 'replies' : 'reply'}`}
          </button>
        )}
      </div>
      <div className={styles['replies--list']}>
        {replies.map(reply => (
          <Reply
            key={reply._id}
            reply={reply}
            updateComment={updateComment}
            parentCommentId={parentCommentId}
            ref={reply._id === replyId ? chosenReply : null}
          />
        ))}
      </div>
    </div>
  );
}

export default ReplyList;
