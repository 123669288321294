import { useEffect, useState } from 'react';

import CodeBlock from './CodeBlock';
import { formatCode } from '../utils/codeFormatter';

const AsyncCodeFormatter = ({ code, language }) => {
  const [formattedCode, setFormattedCode] = useState(null);
  useEffect(() => {
    const format = async () => {
      const { error, formatted } = await formatCode(code, language);

      if (error) {
        console.error('Error while formatting the code');
      }

      setFormattedCode(formatted);
    };

    format();
  }, [code]);

  return formattedCode === null ? (
    <>Loading...</>
  ) : (
    <CodeBlock code={formattedCode} style={'comment'} language={`language-${language}`} copyOption={true} />
  );
};

export default AsyncCodeFormatter;
