import React, { useState, useContext, useEffect } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faCircleChevronRight,
  faCircleChevronLeft,
  faRightFromBracket,
  faListUl,
} from '@fortawesome/free-solid-svg-icons';
import Modal from '@mui/material/Modal';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeHighlight from 'rehype-highlight';
import Split from 'react-split'; 

import Video from './components/Video';
import Note from './components/Note';
import Quiz from '../quiz/Quiz';
import CommentSection from './components/CommentSection';
import FeedbackForm from './components/FeedbackForm';
import Sidebar from './components/Sidebar';
import sidebarStyles from './styles/Sidebar.module.css'
import { AppContext } from '../../contexts/AppContext';

import styles from './styles/Lesson.module.css';
import './styles/Gutter.css';
import notificationStyles from '../shared/header/styles/NotificationCenter.module.css';

import buddyLogo from '../../images/buddy.png';
import favicongreen3 from '../../images/favicongreen3.png';
import NotificationCenter from '../shared/header/components/NotificationCenter';

function Lesson() {
  let { groupId, lessonId, replyId } = useParams();
  const history = useHistory();

  const [locationKey, setLocationKey] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [video, setVideo] = useState('');
  const [markdown, setMarkdown] = useState('');
  const [lastLessonViewedId, setLastLessonViewedId] = useState('');
  const [quizDetails, setQuizDetails] = useState({});
  const [lessonType, setLessonType] = useState('');
  const isMobile = useMediaQuery({ maxWidth: 1000 });
  const [showMenu, setShowMenu] = useState(false);
  const [modal, setModel] = useState(false);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const show = () => {
    setShowMenu(true);
  };
  const hide = () => {
    setShowMenu(false);
  };
  const [currentTab, setCurrentTab] = useState(() => {
    if (replyId) return 'comments';
    else return 'notes';
  });
  const setTabtoVideo = () => {
    setCurrentTab('video');
  }
  const setTabToNotes = () => {
    setCurrentTab('notes');
  };
  const setTabToComments = () => {
    setCurrentTab('comments');
  };

  const { jwt, groupDetails, authLevel } = useContext(AppContext);
  const [jwtValue, setJwtValue] = jwt;
  const [groupDetailsValue, setgroupDetailsValue] = groupDetails;
  const [authLevelValue, setAuthLevelValue] = authLevel;

  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  const [showFeedbackBtn, setShowFeedbackBtn] = useState(false);

  function handleSubmit(event) {
    event.preventDefault();
    setAnswer('Buddy is thinking...');
    getAnswer();
  }

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': jwtValue,
    },
    body: JSON.stringify({ question: question }),
  };

  function getAnswer() {
    fetch(`${process.env.REACT_APP_SWC_API_URL}/external/answer`, requestOptions)
      .then(async (response) => {
        if (response.ok) {
          let data = await response.json();

          setAnswer(data.answer);
          setQuestion('');
        } else if (response.status === 404 || response.status === 401) {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (groupDetailsValue) {
      const groupDetails = groupDetailsValue.map((groupObj) => {
        if (groupObj._id === groupId) {
          groupObj.lastLessonViewedId = lessonId;
        }
        return {
          ...groupObj,
        };
      });
      setgroupDetailsValue(groupDetails);
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': jwtValue,
      },
    };
    fetch(`${process.env.REACT_APP_SWC_API_URL}/lesson/` + lessonId + '/group/' + groupId, requestOptions)
      .then((response) => {
        if (response.status === 404) {
          localStorage.removeItem('swc-access-token');
          localStorage.removeItem('swc-user-email');
          setJwtValue('');
          history.push('/');
        }
        return response.json();
      })
      .then((data) => {
        if (data.lessonType == 'quiz') {
          setQuizDetails(data);
        } else {
          setVideo(data.videoUrl);
          setMarkdown(data.markdownText);
        }
        setLessonType(data.lessonType);
        setLessons(data.lessons);
        setShowFeedbackBtn(!data.hasFeedback);
      });

    return history.listen((location) => {
      if (history.action === 'POP' && lessonId) {
        setLocationKey([location.key]);
        setLastLessonViewedId(lessonId);
      }
    });
  }, [lastLessonViewedId, locationKey]);

  function viewLessons() {
    if (!lessons) {
      return;
    }
    return lessons.map((lesson) => (
      <option key={lesson._id} value={lesson._id}>
        {lesson.name}
      </option>
    ));
  }

  function changeLesson(event) {
    const chosenLessonId = event.target.value;
    setLastLessonViewedId(event.target.value);
    history.push('/groups/' + groupId + '/' + chosenLessonId);
  }

  function changeLessonPage(lesson) {
    setLastLessonViewedId(lesson);
    setShowMenu(false);
    history.push('/groups/' + groupId + '/' + lesson);
  }

  function displayChangeLessonMenu() {
    let previousLesson;
    let nextLesson;
    lessons.forEach((lesson, index) => {
      if (lesson._id === lessonId) {
        previousLesson = lessons[index - 1];
        nextLesson = lessons[index + 1];
      }
    });
    return (
      <div className={`${styles.header__list} ${styles.header__top__center}`}>
        <FontAwesomeIcon
          className={
            previousLesson ? styles.header__next__previous : `${styles.header__next__previous} ${styles.inactive}`
          }
          icon={faCircleChevronLeft}
          onClick={previousLesson ? () => changeLessonPage(previousLesson._id) : undefined}
        ></FontAwesomeIcon>
        <div className={styles.header__lessons__selector}>
          <Link to={'/groups/' + groupId} className={styles.header__lessons}>
            <FontAwesomeIcon icon={faListUl}></FontAwesomeIcon>
            &nbsp;All Lessons
          </Link>
          <select value={lessonId} onChange={changeLesson} className={styles['lesson-select']}>
            {viewLessons()}
          </select>
        </div>
        <FontAwesomeIcon
          className={nextLesson ? styles.header__next__previous : `${styles.header__next__previous} ${styles.inactive}`}
          icon={faCircleChevronRight}
          onClick={nextLesson ? () => changeLessonPage(nextLesson._id) : undefined}
        ></FontAwesomeIcon>
      </div>
    );
  }

  function openModal() {
    setModel(true);
  }

  function closeModal() {
    setModel(false);
  }

  function renderLesson() {
    if (lessonType === 'quiz') {
      return <Quiz quizDetails={quizDetails} />;
    } else if (lessonType === 'lesson') {
      return isMobile ? (
        <div>
          <div className={styles['tabs--view']}>
            <div className={styles['tabs']}>
              <button
                className={currentTab === 'video' ? styles['tab--btn'] + ' ' + styles['active'] : styles['tab--btn']}
                onClick={showMenu === false ? setTabtoVideo : undefined}
              >
                Video
              </button>
              <button
                className={currentTab === 'notes' ? styles['tab--btn'] + ' ' + styles['active'] : styles['tab--btn']}
                onClick={showMenu === false ? setTabToNotes : undefined}
              >
                Notes
              </button>
            </div>
            <div className={styles['tab--content--wrapper']}>
              <div 
                className={
                  currentTab === 'video' ? styles['tab--content'] + ' ' + styles['active'] : styles['tab--content']
                }
                >
                <Video videoUrl={video} />
                <CommentSection />
              </div>
              <div
                className={
                  currentTab === 'notes' ? styles['tab--content'] + ' ' + styles['active'] : styles['tab--content']
                }
              >
                <Note markdownText={markdown} />
              </div>
            </div>
          </div>
          <div className={styles['buddy__icon__container']} onClick={() => openModal()}>
            <img className={styles['buddy__icon']} src={buddyLogo} />
          </div>
          <div className={styles['buddy__helpertext']}>
            <p>I'm Buddy!</p>
            <p>Click on me to ask a question</p>
          </div>
          <Modal open={modal} onClose={closeModal}>
            <div className={styles['buddy__container']}>
              <div className={styles['buddy__container__input']}>
                <h1>It's me, Buddy! 😊 Ask me anything</h1>
                <textarea
                  placeholder="Write your question here"
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                />
                <button className={styles['buddy__submit']} onClick={handleSubmit}>
                  Submit
                </button>
              </div>
              <div>
                <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeHighlight]}>
                  {answer}
                </ReactMarkdown>
              </div>
            </div>
          </Modal>
        </div>
      ) : (
        <div>
          <Split
            sizes={[50, 50]}
            gutterSize={10}
            gutterAlign="center"
            direction="horizontal"
            className={styles['video__note']}
          >
            <Video videoUrl={video} />
            <Note markdownText={markdown} />
          </Split>
          <div className={styles['buddy__icon__container']} onClick={() => openModal()}>
            <img className={styles['buddy__icon']} src={buddyLogo} />
          </div>
          <div className={styles['buddy__helpertext']}>
            <p>I'm Buddy!</p>
            <p>Click on me to ask a question</p>
          </div>
          <Modal open={modal} onClose={closeModal}>
            <div className={styles['buddy__container']}>
              <div className={styles['buddy__container__input']}>
                <h1>It's me, Buddy! 😊 Ask me anything</h1>
                <textarea
                  placeholder="Write your question here"
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                />
                <button className={styles['buddy__submit']} onClick={handleSubmit}>
                  Submit
                </button>
              </div>
              <div>
                <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeHighlight]}>
                  {answer}
                </ReactMarkdown>
              </div>
            </div>
          </Modal>
        </div>
      );
    } else {
      return <></>;
    }
  }

  return isMobile ? (
    <div className={styles['lesson__small']}>
      {showFeedbackForm && (
        <FeedbackForm setShowFeedbackBtn={setShowFeedbackBtn} setShowFeedbackForm={setShowFeedbackForm}></FeedbackForm>
      )}
      <div>
        <div className={styles.header__small}>
          <Link style={{ zIndex: 2 }} to="/">
            <img className={styles.header__logo} src={favicongreen3} />
          </Link>
          {displayChangeLessonMenu()}
          {showMenu ? (
            <FontAwesomeIcon style={{ zIndex: 2 }} className={styles.header__icon} icon={faChevronUp} onClick={hide} />
          ) : (
            <FontAwesomeIcon
              style={{ zIndex: 2 }}
              className={styles.header__icon}
              icon={faChevronDown}
              onClick={show}
            />
          )}
        </div>
        <div className={showMenu ? `${styles.topMenu} ${styles.active}` : `${styles.topMenu}`}>
          {showFeedbackBtn && (
            <div
              style={{ margin: '1rem 0' }}
              className={styles.header__lesson}
              onClick={() => setShowFeedbackForm(true)}
            >
              Feedback
            </div>
          )}
          {authLevelValue !== 'student' ? (
            <Link to={'/adminportal'}>
              <p className={styles.header__lesson}>Admin Portal</p>
            </Link>
          ) : null}
          <Link
            to={'/'}
            onClick={() => {
              localStorage.clear();
              setJwtValue('');
            }}
          >
            <p className={styles.header__lesson}>
              Sign out
              <FontAwesomeIcon icon={faRightFromBracket} className={styles.signout}></FontAwesomeIcon>
            </p>
          </Link>
        </div>
        <div
          className={showMenu ? `${styles.outsideHeader} ${styles.active}` : `${styles.outsideHeader}`}
          onClick={hide}
        ></div>
      </div>
      <div className={styles.content}>{renderLesson()}</div>
    </div>
  ) : (
    <div className={styles['lesson__large']}>
      {showFeedbackForm && (
        <FeedbackForm setShowFeedbackBtn={setShowFeedbackBtn} setShowFeedbackForm={setShowFeedbackForm}></FeedbackForm>
      )}
      <div className={styles.header}>
        <Link to="/" style={{ zIndex: 2 }}>
          <img className={styles.header__logo} src={favicongreen3} />
        </Link>
        {displayChangeLessonMenu()}
        <div className={styles.header__list}>
          <div className={notificationStyles}>
            <NotificationCenter />
          </div>
          {showFeedbackBtn && (
            <div className={styles.header__item} onClick={() => setShowFeedbackForm(true)}>
              Feedback
            </div>
          )}
          {authLevelValue !== 'student' ? (
            <Link className={styles.header__item} to={'/adminportal'}>
              Admin Portal
            </Link>
          ) : null}
          <Link
            className={styles.header__item}
            to={'/'}
            onClick={() => {
              localStorage.clear();
              setJwtValue('');
            }}
          >
            Sign out
          </Link>
        </div>
      </div>
      <div className={styles['lesson__main']}>
        <div className={sidebarStyles['sidebar']}>
          <Sidebar lessons={lessons} groupId={groupId} onLessonClick={(lessonId) => changeLessonPage(lessonId)} currentLessonId={lessonId} />
        </div>
        <div className={styles['lesson__content']}>
          {renderLesson()}
        </div>
      </div>
    </div>
  );
}

export default Lesson;
