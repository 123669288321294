import React from 'react';

import Header from '../shared/header/Header';
import Footer from '../shared/footer/Footer';
import CallToAction from './components/CallToAction';
import Slides from './components/Slides';
import AboutUs from './components/Aboutus';
import HomeIntro from './components/HomeIntro';
import Partners from './components/Partners';
import PastStudents from './components/PastStudents';
import globalNavigation from '../../config/globalNavigation';

import styles from './styles/Landing.module.css';

function Landing() {
  const doodleStyles = `
	@grid: 1x60 / 100vmax;
	@size: 75.8% 1px;
	justify-self: center;
	background: #0089A3;
	opacity: 0.15;
	transform:
	rotate(-15deg)
	translateX(calc(@sin(@i / 4) * π * 10px));
    `;

  return (
    <>
      <css-doodle class={styles.doodle}>
        <style>{doodleStyles}</style>
      </css-doodle>
      <div className={styles.landingContent}>
        <Header menuItems={globalNavigation.home} doNotShowBars={false} />
        <CallToAction />
        <HomeIntro />
        <AboutUs />
        <Slides />
        <Partners />
        <PastStudents />
        <Footer />
      </div>
    </>
  );
}

export default Landing;
