import React, { useState, useContext, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faBarsProgress } from '@fortawesome/free-solid-svg-icons';

import Header from '../shared/header/Header';
import globalNavigation from '../../config/globalNavigation';
import { AppContext } from '../../contexts/AppContext';

import styles from './styles/Group.module.css';

function Group() {
  const [groupInformation, setgroupInformation] = useState({});
  const [lastLessonOpened, setLastLessonOpened] = useState('');
  const [courseName, setCourseName] = useState('');

  const { jwt, groupDetails } = useContext(AppContext);
  const [jwtValue, setJwtValue] = jwt;
  const [groupDetailsValue, setgroupDetailsValue] = groupDetails;

  let { groupId } = useParams();
  const history = useHistory();

  const [weekNum, setWeekNum] = useState(0);

  useEffect(() => {
    if (groupDetailsValue) {
      const group = groupDetailsValue.filter((group) => group._id === groupId)[0];
      setgroupInformation(group.lessons);
      setCourseName(group.name);
      if (group.lastLessonViewedId) {
        setLastLessonOpened(group.lastLessonViewedId);
      }
    } else {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'x-access-token': jwtValue },
      };
      fetch(`${process.env.REACT_APP_SWC_API_URL}/group?groupId=` + groupId, requestOptions)
        .then((response) => {
          if (response.status === 404) {
            localStorage.removeItem('swc-access-token');
            localStorage.removeItem('swc-user-email');
            setJwtValue('');
            history.push('/');
          }
          return response.json();
        })
        .then((data) => {
          setgroupInformation(data.course.lessons);
          setLastLessonOpened(data.lastLessonViewedId);
          setCourseName(data.course.name);

          data.course._id = data._id;
          setgroupDetailsValue([data.course]);
        });
    }
  }, [groupDetailsValue]);

  function displayLastLesson() {
    const lastLesson = groupInformation.filter((lesson) => lesson._id === lastLessonOpened);
    return lastLesson.length !== 0 ? (
      <div key={groupId} className={styles['course--lessonlist']}>
        <p className={styles['course--lessontext']}>This Is The Last Lesson You Viewed</p>
        <div
          className={styles['course--lesson']}
          onClick={() => history.push('/groups/' + groupId + '/' + lastLessonOpened)}
        >
          <p>{lastLesson[0].name}</p>
        </div>
      </div>
    ) : (
      ''
    );
  }

  const retake = (lessonId) => (e) => {
    e.stopPropagation();
    const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json', 'x-access-token': jwtValue },
      body: JSON.stringify({ lessonId: lessonId }),
    };

    fetch(`${process.env.REACT_APP_SWC_API_URL}/user/retakequiz`, requestOptions)
      .then((res) => {
        if (res.status === 200) {
          history.push('/groups/' + groupId + '/' + lessonId);
        } else console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function displayAllLessons() {
    if (Object.keys(groupInformation).length === 0) {
      return;
    }
    return groupInformation.map((lesson) => {
      const isQuiz = lesson.lessonType === 'quiz';
      if (lesson.week === weekNum || weekNum === 0) {
        return (
          <div
            key={lesson._id}
            className={styles['course--lesson'] + ' ' + (isQuiz ? styles['course--quiz--lesson'] : '')}
            onClick={() => history.push('/groups/' + groupId + '/' + lesson._id)}
          >
            <p className={styles['course--lessonname']}>
              <span>{lesson.name}</span>
              {isQuiz && new Date() - new Date(lesson.quizCompleteTime) > 86400000 && !lesson.retook ? (
                <span onClick={retake(lesson._id)} className={styles['retake-button']}>
                  Retake
                </span>
              ) : (
                ''
              )}
            </p>
            <p className={styles['course--lessondescription']}>{lesson.description}</p>
            {displayLessonStatus(lesson)}
          </div>
        );
      }
    });
  }

  function displayLessonStatus(lesson) {
    if (lesson.lessonType === 'lesson' && (lesson.percWatched === undefined || lesson.percWatched === 0)) {
      return <p className={styles['course--notstarted']}>Start Lesson</p>;
    }
    if (lesson.lessonType === 'lesson' && lesson.percWatched < 95) {
      return (
        <div className={styles['course--lessonstatus--progressbar']}>
          <div className={styles['course--progressbar--container']}>
            <div className={styles['course--progressbar']} style={{ width: `${lesson.percWatched}%` }} />
          </div>
          <div className={styles['course--percentage']}> {lesson.percWatched}% </div>
        </div>
      );
    }
    if (lesson.lessonType === 'lesson' && lesson.percWatched >= 95) {
      return (
        <div className={styles['course--lessonstatus--progressbar']}>
          <div className={styles['course--progressbar--container']}>
            <div className={styles['course--progressbar--completed']} />
          </div>
          <div className={styles['course--percentage']}>
            <FontAwesomeIcon className={styles['course--completed']} icon={faCheckCircle} />
          </div>
        </div>
      );
    }

    if (
      lesson.lessonType === 'quiz' &&
      (lesson.quizComplete === undefined || (!lesson.quizComplete && !lesson.quizAnswers.length))
    ) {
      return <p className={styles['course--notstarted']}>Start Quiz</p>;
    }
    if (lesson.lessonType === 'quiz' && !lesson.quizComplete && lesson.quizAnswers.length) {
      return (
        <span className={styles['course-completedquiz']}>
          <p className={styles['course--quizscore']}>Continue</p>
          <FontAwesomeIcon className={styles['course--quizstarted']} icon={faBarsProgress} />
        </span>
      );
    }
    if (lesson.lessonType === 'quiz' && lesson.quizComplete) {
      return (
        <span className={styles['course-completedquiz']}>
          <p className={styles['course--quizscore']}>Your Best Grade: {lesson.score}%</p>
          <FontAwesomeIcon className={styles['course--completed']} icon={faCheckCircle} />
        </span>
      );
    }
  }

  const viewWeekNum = () => {
    if (!groupInformation || Object.keys(groupInformation).length === 0) return;
    const totalWeekNum = groupInformation[groupInformation.length - 1]['week'];
    const options = [];
    for (let i = 0; i <= totalWeekNum; i++) {
      options.push(
        <option key={i} value={i}>
          {i === 0 ? 'All Lessons' : `Week ${i}`}
        </option>
      );
    }
    return options;
  };

  const changeWeekNum = (e) => {
    setWeekNum(parseInt(e.target.value));
  };

  return (
    <div className={styles.course}>
      <div className={styles['course--content']}>
        <Header menuItems={globalNavigation.groups} />
        <p className={styles['course--name']}>{courseName}</p>
        <select value={weekNum} onChange={changeWeekNum} className={styles['week-select']} styles={{ margin: 'auto' }}>
          {viewWeekNum()}
        </select>
        <div>{lastLessonOpened && displayLastLesson()}</div>
        <div className={styles['course--lessonlist']}>
          <p className={styles['course--lessontext']}>Your Available Lessons</p>
          {displayAllLessons()}
        </div>
      </div>
    </div>
  );
}

export default Group;
