import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { profanity } from '@2toad/profanity';

import { AppContext } from '../../../contexts/AppContext';
import ConfirmBox from './ConfirmBox';

import styles from '../styles/FeedbackForm.module.css';

function FeedbackForm({ setShowFeedbackBtn, setShowFeedbackForm }) {
  let { lessonId } = useParams();
  const [charLeft, setCharLeft] = useState(200);
  const [content, setContent] = useState('');
  const { jwt, toast } = useContext(AppContext);
  const [jwtValue, setJwtValue] = jwt;
  const [toastValue, setToastValue] = toast;
  const [showConfirmBox, setShowConfirmBox] = useState(false);

  const handleChange = (e) => {
    setContent(e.target.value);
    setCharLeft(200 - e.target.value.length);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': jwtValue,
      },
      body: JSON.stringify({
        lessonId: lessonId,
        content: content,
      }),
    };
    fetch(`${process.env.REACT_APP_SWC_API_URL}/feedback`, requestOptions)
      .then((res) => {
        if (res.status === 200) {
          setShowFeedbackForm(false);
          setShowConfirmBox(false);
          setShowFeedbackBtn(true);
          setToastValue({
            checkMark: true,
            message: 'Thank you for submitting your feedback.',
            time: 3,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmitClick = () => {
    // if profanity exists, create a popup like when we go to delete comment, tell user they need to edit their comment or something like that.
    if (profanity.exists(content)) {
      return setToastValue({
        checkMark: false,
        message: 'Please do not use foul language! Try again!',
        time: 3,
      });
    }
    setShowConfirmBox(true);
  };

  return (
    <div className={styles['feedback-form-container']}>
      <div className={styles['feedback-form']}>
        <h4>Tell us what you like about this lesson or how we can improve:</h4>
        <h5 className={styles['reminder']}>You can only submit one feedback for each lesson</h5>
        <textarea rows={10} cols={100} maxLength={200} onChange={(e) => handleChange(e)}></textarea>
        <div className={styles[charLeft <= 0 ? 'no-char-left' : 'char-left']}>Characters left: {charLeft}</div>
        <button
          className={content.length === 0 ? styles['inactive'] : styles['active']}
          onClick={() => handleSubmitClick()}
        >
          Submit
        </button>
        <FontAwesomeIcon
          className={styles['close']}
          icon={faXmark}
          size="3x"
          onClick={() => setShowFeedbackForm(false)}
        />
      </div>
      {showConfirmBox && (
        <ConfirmBox
          message={'Submit your feedback?'}
          confirm={handleSubmit}
          setShowConfirmBox={setShowConfirmBox}
          confirmText={'Submit'}
        />
      )}
    </div>
  );
}

export default FeedbackForm;
