import { useEffect, useRef, useState } from 'react';
import highlight from 'highlight.js';
import javascript from 'highlight.js/lib/languages/javascript';
import css from 'highlight.js/lib/languages/css';
import html from 'highlight.js/lib/languages/xml';
import 'highlight.js/styles/night-owl.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

import styles from '../styles/CodeBlock.module.css';

highlight.registerLanguage('javascript', javascript);
highlight.registerLanguage('html', html);
highlight.registerLanguage('css', css);

const styleOptions = new Map([
  ['comment', styles['comment-code-block']],
  ['markdown', styles['markdown-code-block']],
  ['line', styles['code-line']],
]);

const CodeBlock = ({ code, language = '', style = '', copyOption = false }) => {
  const [copied, setCopied] = useState(false);
  const codeRef = useRef();

  useEffect(() => {
    if (codeRef && codeRef.current) {
      highlight.highlightElement(codeRef.current);
    }
  }, [code]);

  const handleCopy = () => {
    navigator.clipboard.writeText(code);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <pre className={styleOptions.get(style)}>
      {copyOption && (
        <div className={styles['code-copy-icon']}>
          {copied && <div className={styles.popup}>Copied to Clipboard</div>}
          <button onClick={handleCopy}>
            <FontAwesomeIcon icon={faCopy} color="#fff" />
          </button>
        </div>
      )}
      <code className={language} ref={codeRef}>
        {code}
      </code>
    </pre>
  );
};

export default CodeBlock;
