import React, { useState } from 'react';

import styles from '../styles/Quiz.module.css';

function QuizQuestion({ index, numberOfQuestions, question, nextQuestion, onClick }) {
  const [selectedAnswers, setSelectedAnswers] = useState(new Array(numberOfQuestions).fill(false));

  function renderAnswerChoices() {
    return question.answers.map((answer, key) => {
      let additionalClasses = '';
      if (selectedAnswers[key]) {
        additionalClasses = ' ' + styles['quiz-answer-selected'];
      }
      return (
        <div className={styles['quiz-answer'] + additionalClasses} key={key} onClick={() => onAnswerClick(key)}>
          {answer.answerChoice}
        </div>
      );
    });
  }

  function onAnswerClick(key) {
    let updatedSelectedAnswers = selectedAnswers.slice();
    if (question.questionType === 'MC') {
      updatedSelectedAnswers.fill(false);
    }
    updatedSelectedAnswers[key] = !updatedSelectedAnswers[key];
    setSelectedAnswers(updatedSelectedAnswers);
  }

  function onNextQuestionClick() {
    let answers = [];
    for (let index = 0; index < question.answers.length; index++) {
      if (selectedAnswers[index]) {
        answers.push(index);
      }
    }
    if (!answers.length) {
      return;
    }
    if (index !== numberOfQuestions) {
      setSelectedAnswers(new Array(nextQuestion.answers.length).fill(false));
    }
    onClick(answers);
  }

  return (
    <div className={styles['quiz-questions']}>
      <div className={styles['quiz-questionNumber']}>
        Question {index} of {numberOfQuestions}
      </div>
      <div className={styles['quiz-question']}>{question.question}</div>
      {question.questionType === 'MC' && <div className={styles['quiz-mc-ms']}>*You can select only one answer</div>}
      {question.questionType === 'MS' && <div className={styles['quiz-mc-ms']}>*You can select multiple answers</div>}
      <div className={styles['quiz-answerChoices']}>{renderAnswerChoices()}</div>
      <div className={styles['quiz-nextQuestion-button-container']} onClick={() => onNextQuestionClick()}>
        <div
          className={
            styles['quiz-nextQuestion-button'] +
            (selectedAnswers.includes(true) ? ' ' + styles['quiz-nextQuestion-button-hoverable'] : '')
          }
        >
          {index == numberOfQuestions ? 'Finish Quiz' : 'Next Question'}
        </div>
      </div>
    </div>
  );
}

export default QuizQuestion;
