import React from 'react';

import styles from '../styles/ConfirmBox.module.css';

function ConfirmBox({ message, confirm, setShowConfirmBox, confirmText }) {
  return (
    <div className={styles['confirm--box--container']} onClick={() => setShowConfirmBox(false)}>
      <div className={styles['confirm--box']}>
        <h5>{message}</h5>
        <button className={styles['button-cancel']} onClick={() => setShowConfirmBox(false)}>
          Cancel
        </button>
        <button className={styles['button-confirm']} onClick={(e) => confirm(e)}>
          {confirmText}
        </button>
      </div>
    </div>
  );
}

export default ConfirmBox;
