import Groups from '../pages/groups/Groups';
import Group from '../pages/group/Group';
import Lesson from '../pages/lesson/Lesson';
import ResetPassword from '../pages/resetPassword/ResetPassword';
import AdminOrgs from '../pages/admin/AdminOrgs'
import AdminGrps from '../pages/admin/AdminGrps'
import AdminUsers from '../pages/admin/AdminUsers'
import AdminUser from '../pages/admin/AdminUser'

const protectedRoutes = [
    {
        path: '/',
        component: Groups
    },
    {
        path: '/groups',
        component: Groups
    },
    {
        path: '/groups/:groupId',
        component: Group
    },
    {
        path: '/groups/:groupId/:lessonId',
        component: Lesson
    },
    {
        path: '/groups/:groupId/:lessonId/:replyId',
        component: Lesson
    },
    {
        path: '/login',
        component: Groups
    },
    {
        path: '/reset',
        component: ResetPassword
    },
    {
        path: '/adminportal',
        component: AdminOrgs
    },
    {
        path: '/adminportal/:orgId',
        component: AdminGrps
    },
    {
        path: '/adminportal/:orgId/:grpId',
        component: AdminUsers
    },
    {
        path: '/adminportal/:orgId/:grpId/:userId',
        component: AdminUser
    }
];

export default protectedRoutes;