import ProjectCard from './ProjectCard';
import styles from '../styles/PastStudents.module.css';
import cookbook from '../../../images/pastStudents_cookbook.png';
import fashion from '../../../images/pastStudents_fashion.png';
import hobbies from '../../../images/pastStudents_hobbies.png';

const projectsExamples = [
  {
    id: 'id1',
    img: hobbies,
    name: "Jeanelle's Hobbies & Music 🎵",
    description:
      'The showcased project is a vibrant personal website created by a student, Jeanelle, featuring a welcoming homepage with a playful panda graphic and an introduction to her coding journey through the "Coding for Champions" program. The "About Me" page shares her personal interests and future aspirations, while the "My Hobbies" section showcases activities like roller skating, biking, and playing guitar, each represented by interactive images. The "Favorite Foods" section highlights her culinary favorites, such as bubble waffles and poutine, complete with clickable images for recipes. Additionally, the "Favorite Artists" page reflects her musical tastes, featuring artist photos and links to profiles. This project demonstrates Janelle’s proficiency in HTML and CSS, creative design sense, and ability to organize content effectively. The website is a testament to her technical skills and a platform for personal expression, making it an engaging and user-friendly experience for visitors.',
  },
  {
    id: 'id2',
    img: fashion,
    name: "Maryama's Religion and Fashion",
    description:
      'The "Muslim Fashion" project features a comprehensive website that educates viewers about traditional and modern Muslim clothing for both women and men. The women\'s section introduces the abaya, a long, loose dress, detailing its evolution and cultural significance in promoting modesty. The men\'s section highlights the kufi, a traditional hat, explaining its variations and religious importance. The project showcases the student\'s skills in web design, cultural research, and educational content creation, resulting in a visually appealing and informative resource. The provided images have been edited to enhance clarity and presentation, aligning with the educational focus of the project.',
  },
  {
    id: 'id3',
    img: cookbook,
    name: 'Jenushan\'s Vegetarian Cookbook',
    description:
      'The "Cookbook" project showcases a student-created website dedicated to sharing favorite recipes, with a special focus on Roti and Eggplant Curry. The site includes detailed instructions, ingredients list, and cooking tips, reflecting the student\'s passion for culinary arts. The provided image has been edited to enhance clarity, emphasizing the ease of following the recipe. This project highlights the student\'s skills in web design and content creation, offering an engaging and practical resource for cooking enthusiasts.',
  },
  // {
  //   id: 'id4',
  //   img: 'https://images.unsplash.com/photo-1622050756792-5b1180bbb873?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  //   name: 'Project Name 4',
  //   description:
  //     'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
  // },
];

const PastStudents = () => {
  return (
    <div className={styles['past-students-container']}>
      <h3>
        Past Students Projects
        <span />
      </h3>
      <div className={styles['project-list']}>
        {projectsExamples.map((project) => (
          <ProjectCard key={project.id} project={project} />
        ))}
      </div>
    </div>
  );
};

export default PastStudents;
