import React, { useState, } from 'react';

import Snackbar from '@mui/material/Snackbar';

import JoinModal from './JoinModal';

import swcIntro from '../../../images/swcIntro.svg';

import styles from '../styles/CallToAction.module.css';

export default function CallToAction() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');

  function closeSnackBar(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  }

  return (
    <div className={styles['home-intro-container']}>
      <div className={styles['home-intro-cta']}>
      <Snackbar
        open={snackOpen}
        autoHideDuration={3000}
        onClose={closeSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={snackMessage}
      />
        <h1>
          Welcome to <br /> Students Who Code
        </h1>
        <p>
          Empowering Tomorrow's Innovators, <br /> One Line of Code at a Time!
        </p>
        <button onClick={handleOpen} className={styles['join-button']}>Join Today</button>
        <JoinModal 
          open={open} 
          handleClose={handleClose} 
          setSnackOpen={setSnackOpen} 
          setSnackMessage={setSnackMessage}
        />
      </div>
      <img src={swcIntro} className={styles['intro-image']} />
    </div>
  );
}
