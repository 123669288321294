import React, { useMemo } from 'react';
import { useTable, useSortBy, useBlockLayout, useGlobalFilter } from 'react-table';
import { useSticky } from 'react-table-sticky';

import { GlobalFilter } from './GlobalFilter';

import adminStyles from '../styles/AdminPortal.module.css';
import { Styles } from '../styles/StatsTableStyles';

function StatsTable(props) {
  const columns = useMemo(() => props.columns, []);
  const data = useMemo(() => props.rows, []);

  const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow, state, setGlobalFilter } =
    useTable(
      {
        columns,
        data,
        initialState: { hiddenColumns: ['hidden'], columns },
      },
      useBlockLayout,
      useSticky,
      useGlobalFilter,
      useSortBy
    );

  const { globalFilter } = state;

  return (
    <div className={adminStyles['table--container']}>
      <div className={adminStyles['empty1']}></div>
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      <div className={adminStyles['empty2']}></div>
      <div className={adminStyles['stats--table']}>
        <Styles>
          <div {...getTableProps()} className="table sticky">
            <div className="header">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="tr">
                  {headerGroup.headers.map((column) => (
                    <div {...column.getHeaderProps(column.getSortByToggleProps())} className="th">
                      {column.render('Header')}
                      <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div {...getTableBodyProps()} className="body">
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <div {...row.getRowProps()} className="tr">
                    {row.cells.map((cell) => (
                      <div {...cell.getCellProps()} className="td">
                        {cell.render('Cell')}
                      </div>
                    ))}
                  </div>
                );
              })}
            </div>
            <div className="footer">
              {footerGroups.map((footerGroup) => (
                <div {...footerGroup.getHeaderGroupProps()} className="tr">
                  {footerGroup.headers.map((column) => (
                    <div {...column.getHeaderProps()} className="td">
                      {column.render('Footer')}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </Styles>
      </div>
    </div>
  );
}

export default StatsTable;
