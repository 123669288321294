import React from 'react';

import styles from '../styles/Quiz.module.css';

function QuizStart({ name, details, onClick }) {
  return (
    <div className={styles['quiz-start']}>
      <div className={styles['quiz-name']}>{name}</div>
      <div className={styles['quiz-description']}>{details}</div>
      <div className={styles['quiz-startButton-container']}>
        <div className={styles['quiz-startButton']} onClick={() => onClick()}>
          Start Quiz
        </div>
      </div>
    </div>
  );
}

export default QuizStart;
