import styles from '../styles/ProjectCard.module.css';

const ProjectCard = ({ project }) => {
  return (
    <div className={styles['project-demo']}>
      <img src={project.img} alt="project demo image" />
      <div>
        <h4>{project.name}</h4>
        <p>{project.description}</p>
      </div>
    </div>
  );
};

export default ProjectCard;
