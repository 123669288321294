import React, { useState, useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';

import UpVoteButton from './UpvoteButton';
import ReplyList from './ReplyList';
import AddReplyForm from './AddReplyForm';
import AsyncCodeFormatter from './AsyncCodeFormatter';
import { timeSince } from '../utils/time';
import { AppContext } from '../../../contexts/AppContext';

import styles from '../styles/Comments.module.css';

function Comment({ comment, updateComment }) {
  let { replyId } = useParams();
  const commentRef = useRef(null);

  const date = new Date(comment.createdAt);

  const [showReplyForm, setShowReplyForm] = useState(false);
  const changeShowReplyForm = () => {
    setShowReplyForm(prevState => !prevState);
  };

  const [showReplyList, setShowReplyList] = useState(() => {
    if (replyId) {
      const reply = comment.replies.find((reply) => reply._id === replyId);
      return !!reply; // Ensure it returns true if a matching reply is found
    }
    return false; // Default to false if no replyId is present
  });
  
  const changeShowReplyList = () => {
    setShowReplyList(prev => !prev); // Toggle visibility of the reply list
  };

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const { jwt } = useContext(AppContext);
  const [jwtValue, setJwtValue] = jwt;

  /*
  useEffect(() => {
    if (replyId && comment._id === replyId && commentRef.current) {
      commentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [replyId, comment._id]);
*/

  function fakeDeleteComment(commentId) {
    const requestOptions = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': jwtValue,
      },
    };
    fetch(`${process.env.REACT_APP_SWC_API_URL}/comment/fakedelete/${commentId}`, requestOptions)
      .then((res) => {
        if (res.status === 200) {
          updateComment('deleteTopLevelComment', commentId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function displayConfirmBox() {
    return (
      <div className={styles['confirm--box--container']} onClick={() => setShowDeleteConfirm(false)}>
        <div className={styles['confirm--box']}>
          <div>Delete your comment?</div>
          <button onClick={() => setShowDeleteConfirm(false)}>Cancel</button>
          <button onClick={() => fakeDeleteComment(comment._id)}>Delete</button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles['comment--and--reply']} ref={commentRef}>
      <div className={styles['comment']}>
        <div className={styles['comment--header']}>
          <div>
            <span className={styles['comment--name']}>{`${comment.userId.fname} ${comment.userId.lname}`}</span>
            {comment.userId.authLevel !== 'student' ? (
              <span
                className={
                  comment.userId.authLevel === 'teacher'
                    ? styles['comment--role'] + ' ' + styles['teacher']
                    : comment.userId.authLevel === 'orgadmin'
                    ? styles['comment--role'] + ' ' + styles['orgadmin']
                    : styles['comment--role'] + ' ' + styles['swcadmin']
                }
              >
                {comment.userId.authLevel === 'teacher' ? 'Teacher' : ''}
                {comment.userId.authLevel === 'orgadmin' ? 'Org Admin' : ''}
                {comment.userId.authLevel === 'swcadmin' ? 'SWC Admin' : ''}
              </span>
            ) : (
              ''
            )}
            <span className={styles['comment--date']}>{timeSince(date)}</span>
          </div>
          {comment.isOwner ? (
            <div className={styles['comment--delete']}>
              <FontAwesomeIcon onClick={() => setShowDeleteConfirm(true)} icon={faTrashCan} />
            </div>
          ) : (
            ''
          )}
          {showDeleteConfirm && displayConfirmBox()}
        </div>
        {comment.content.map(({ _id, content, type, language }) => (
          <div className={styles['comment--content']} key={_id}>
            {type === 'code' ? <AsyncCodeFormatter code={content} language={language} /> : content}
          </div>
        ))}
        <div className={styles['comment--footer']}>
          <UpVoteButton
            key={comment._id}
            hasUpvoted={comment.hasUpvoted}
            upvoteCount={comment.upVotes.length}
            commentId={comment._id}
            updateComment={updateComment}
          ></UpVoteButton>
          <button
            className={styles['reply--btn']}
            onClick={changeShowReplyForm}
          >
            Reply
          </button>
        </div>
        {showReplyForm && (
          <div className={styles['reply--form--active']}>
            <AddReplyForm
              parentCommentId={comment._id}
              setShowReplyForm={setShowReplyForm}
              updateComment={updateComment}
              setShowReplyList={setShowReplyList}
            />
          </div>
        )}
      </div>
      {comment.replies.length > 0 ? (
        <ReplyList
          replies={comment.replies}
          updateComment={updateComment}
          parentCommentId={comment._id}
          showReplyList={showReplyList}
          changeShowReplyList={changeShowReplyList}
        />
      ) : null}
    </div>
  );
}

export default Comment;