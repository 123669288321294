import * as prettier from 'prettier';
import * as parserBabel from 'prettier/plugins/babel';
import * as parserHtml from 'prettier/plugins/html';
import * as parserEstree from 'prettier/plugins/estree';
import * as parserCss from 'prettier/plugins/postcss';

export const formatCode = async (code, language) => {
  let formatted = '';
  const result = {};
  const plugins = [];

  if (language === 'javascript') {
    plugins.push(parserBabel);
    plugins.push(parserEstree);
  }

  if (language === 'css') {
    plugins.push(parserCss);
  }

  if (language === 'html') {
    plugins.push(parserHtml);
  }

  try {
    formatted = await prettier.format(code, {
      parser: language === 'javascript' ? 'babel' : language,
      plugins,
    });
  } catch (error) {
    result.error = error;
    formatted = code;
  }

  result.formatted = formatted;
  return result;
};
