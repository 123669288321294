import React, { useState, useEffect, useContext } from 'react';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AppContext } from '../../../contexts/AppContext';

import styles from './styles/Toast.module.css';

const Toast = () => {
  const { toast } = useContext(AppContext);
  const [toastValue, setToastValue] = toast;
  const [countdown, setCountdown] = useState(toastValue.time);
  const [progress, setProgress] = useState('100%');

  useEffect(() => {
    if (countdown > 0) {
      const interval = setInterval(() => {
        setCountdown((countdown) => countdown - 0.1);
      }, 100);
      return () => clearInterval(interval);
    }
  }, [countdown]);

  useEffect(() => {
    if (countdown > 0) {
      const progress = countdown / toastValue.time;
      setProgress(`${progress * 100}%`);
    } else {
      setProgress('0%');
    }
  }, [countdown]);

  useEffect(() => {
    if (progress === '0%') {
      setToastValue(null);
    }
  }, [progress]);

  return (
    <div className={styles['popup']}>
      {toastValue.checkMark ? (
        <FontAwesomeIcon className={styles.checkmark} icon={faCircleCheck}></FontAwesomeIcon>
      ) : (
        <FontAwesomeIcon className={styles.xmark} icon={faCircleXmark}></FontAwesomeIcon>
      )}
      <span className={styles.message}>{toastValue.message}</span>
      <div className={styles['countdown-bar']}>
        <div className={styles.progress} style={{ width: progress }}></div>
      </div>
    </div>
  );
};

export default Toast;
