import React, { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Header from '../shared/header/Header';
import globalNavigation from '../../config/globalNavigation';
import { AppContext } from '../../contexts/AppContext';

import styles from './styles/ResetPassword.module.css';

const ResetPassword = () => {
  const { jwt, toast } = useContext(AppContext);
  const [jwtValue, setJwtValue] = jwt;
  const [toastValue, setToastValue] = toast;

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showOldPw, setShowOldPw] = useState(false);
  const [showNewPw, setShowNewPw] = useState(false);
  const toggleShowPw = (pwType) => {
    if (pwType === 'old') setShowOldPw(!showOldPw);
    if (pwType === 'new') setShowNewPw(!showNewPw);
  };
  const [showIncorrectOldPw, setShowIncorrectOldPw] = useState(false);
  const [minChar, setMinChar] = useState(false);
  const [lowerCase, setLowerCase] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [num, setNum] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [notSame, setNotSame] = useState(false);

  const currentPwRef = useRef(null);
  const newPwRef = useRef(null);

  const history = useHistory();

  function resetPasswordCall() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'x-access-token': jwtValue },
      body: JSON.stringify({ oldPassword: oldPassword, newPassword: newPassword }),
    };

    fetch(`${process.env.REACT_APP_SWC_API_URL}/auth/resetPassword`, requestOptions)
      .then(async (response) => {
        if (response.ok) {
          setToastValue({
            checkMark: true,
            message: 'Your password has been changed.',
            time: 3,
          });
          history.push('/');
        }
        if (response.status === 401) {
          setShowIncorrectOldPw(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleSubmit(event) {
    event.preventDefault();
    resetPasswordCall();
  }

  const validate = (e) => {
    setNewPassword(e.target.value);
    if (e.target.value.length >= 8) setMinChar(true);
    else setMinChar(false);
    if (/[a-z]/.test(e.target.value)) setLowerCase(true);
    else setLowerCase(false);
    if (/[A-Z]/.test(e.target.value)) setUpperCase(true);
    else setUpperCase(false);
    if (/\d/.test(e.target.value)) setNum(true);
    else setNum(false);
    if (/[@$!%*?&]/.test(e.target.value)) setSpecialChar(true);
    else setSpecialChar(false);
    if (oldPassword !== e.target.value) setNotSame(true);
    else setNotSame(false);
  };

  const checkNotSame = (e) => {
    setOldPassword(e.target.value);
    if (e.target.value !== newPassword) setNotSame(true);
    else setNotSame(false);
  };

  const handleFocus = (e) => {
    if (e.target.id === 'current') {
      currentPwRef.current.classList.add(styles['pw-holder-focus']);
    }
    if (e.target.id === 'new') {
      newPwRef.current.classList.add(styles['pw-holder-focus']);
    }
  };

  const handleBlur = (e) => {
    if (e.target.id === 'current') {
      currentPwRef.current.classList.remove(styles['pw-holder-focus']);
    }
    if (e.target.id === 'new') {
      newPwRef.current.classList.remove(styles['pw-holder-focus']);
    }
  };

  const doodleStyles = `
    @grid: 1 / 100vw;
    @content: @svg(
    viewBox: 0 16 (20 / (1 / 100vw)) (20 / (1 / 100vh));
    stroke: #fff;
    stroke-opacity: .1;
    stroke-width: .04;
    stroke-linecap: round;
    line*32x32 {
      draw: @r(2s);
      x1, y1, x2, y2: @p(
      @nx(-1) @ny(-1) @nx @ny,
      @nx @ny(-1) @nx(-1) @ny,
      @nx @ny(-1) @nx @ny
      );
    }
    );`;



  return (
    <div className={styles.resetPassword}>
      <css-doodle class={styles.doodle}>
        <style>
          {doodleStyles}
        </style>
      </css-doodle>
      <div className={styles['resetPassword--content']}>
        <Header menuItems={globalNavigation.reset} />
        <div className={styles['form-container']}>
          <div className={styles['form']}>
            <form>
            <p className={styles['form-title']}>Change Your Password</p>
              <div ref={currentPwRef} className={styles['pw-holder']}>
                <input
                  id="current"
                  type={showOldPw ? 'text' : 'password'}
                  value={oldPassword}
                  onChange={(e) => {
                    checkNotSame(e);
                  }}
                  placeholder="Current Password"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
                <span
                  onClick={() => {
                    toggleShowPw('old');
                  }}
                >
                  {showOldPw ? 'Hide' : 'Show'}
                </span>
              </div>
              {showIncorrectOldPw && (
                <div className={styles['incorrent-pw']}>Incorrect Password</div>
              )}
              <div ref={newPwRef} className={styles['pw-holder']}>
                <input
                  id="new"
                  type={showNewPw ? 'text' : 'password'}
                  value={newPassword}
                  onChange={(e) => {
                    validate(e);
                  }}
                  placeholder="New Password"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
                <span
                  onClick={() => {
                    toggleShowPw('new');
                  }}
                >
                  {showNewPw ? 'Hide' : 'Show'}
                </span>
              </div>
              <div className={styles['critera-container']}>
                <div className={minChar ? styles.green : styles.red}>1. At least 8 characters long</div>
                <div className={lowerCase ? styles.green : styles.red}>2. Contains at least one lowercase letter</div>
                <div className={upperCase ? styles.green : styles.red}>3. Contains at least one uppercase letter</div>
                <div className={num ? styles.green : styles.red}>4. Contains at least one number</div>
                <div className={specialChar ? styles.green : styles.red}>
                  5. Contains at least one special character: @$!%*?&
                </div>
                <div className={notSame ? styles.green : styles.red}>
                  6. Current password and new password must be different
                </div>
              </div>
              <button
                onClick={handleSubmit}
                className={
                  !minChar || !lowerCase || !upperCase || !num || !specialChar || !notSame || oldPassword === ''
                    ? styles.disabled
                    : styles.enabled
                }
              >
                Reset Password
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
