import React, { useState, useEffect, createContext } from 'react';

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [jwt, setJwt] = useState('');
  const [groups, setGroups] = useState();
  const [groupDetails, setGroupDetails] = useState();
  const [authLevel, setAuthLevel] = useState();
  const [toast, setToast] = useState(null);

  useEffect(() => {
    setJwt(JSON.parse(localStorage.getItem('swc-access-token')));
    setAuthLevel(JSON.parse(localStorage.getItem('swc-user-authLevel')));
  }, []);

  return (
    <AppContext.Provider
      value={{
        jwt: [jwt, setJwt],
        groups: [groups, setGroups],
        groupDetails: [groupDetails, setGroupDetails],
        authLevel: [authLevel, setAuthLevel],
        toast: [toast, setToast],
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
