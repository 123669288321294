import styled from 'styled-components';

export const Styles = styled.div`
  .td {
    padding: 5px;
  }
  .table {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    border: 1px solid #ddd;
    font-size: 1rem;
    color: black;
    min-height: fit-content;
    max-height: 500px;
    text-align: right;
    width: 100%;
    .tr {
      :last-child {
        .td {
          border-bottom: 1px solid #ddd;
        }
      }
    }
    .tr:hover .td {background-color: gold;}
    .th {
        background-color: #435DB8;
        padding: 20px 5px 20px 5px;
    },
    .td {  
      background-color: white;
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      overflow: hidden;
      :last-child {
        border-right: 0;
      }
    }
    &.sticky {
      overflow: auto;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }
      .header {
        top: 0;
        box-shadow: 0px 3px 3px #ccc;
        padding: 0;
        margin: 0;
        height: fit-content;
        background-color: #435DB8;
        color: white;
      }
      .body {
        position: relative;
        z-index: 0;
      }
      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }
      [data-sticky-td] {
        position: sticky;
        text-align: left;
      }
      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc;
      }
      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #ccc;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .table {
      font-size: 0.6rem;
    }
  }

  .table.sticky::-webkit-scrollbar {
    width: 7.5px;
    height: 7.5px;
  }

  .table.sticky::-webkit-scrollbar-track {
    background: white;
  }

  .table.sticky::-webkit-scrollbar-thumb {
    background: darkgray;
    border-radius: 15px
  }

  .table.sticky::-webkit-scrollbar-thumb:hover {
    background: gray;
  }
`