import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/Sidebar.module.css';

const Sidebar = ({ lessons, groupId, onLessonClick, currentLessonId }) => {
  return (
    <div className={styles.sidebar}>
      <ul className={styles.sidebar__list}>
        {lessons.map((lesson) => (
          <li key={lesson._id}>
            <Link
              to={`/groups/${groupId}/${lesson._id}`}
              className={styles.sidebar__link}
              onClick={() => onLessonClick(lesson._id)}
            >
              {lesson.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;