const Arrow = (rotation) => (
  <button
    style={{
      width: '30px',
      height: '30px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'rgba(13, 9, 24, 0.4)',
      borderRadius: '100%',
      border: '0px',
      transform: `rotate(${rotation})`,
      cursor: 'pointer',
    }}
  >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="#fff">
      <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"></path>
    </svg>
  </button>
);

export default Arrow;
