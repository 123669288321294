import { useContext } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import routes from './config/routes';
import protectedRoutes from './config/protectedRoutes';
import { AppContext } from './contexts/AppContext';

import Toast from './pages/shared/toast/Toast';

function App() {
  const { jwt, toast } = useContext(AppContext);
  const [jwtValue, setJwtValue] = jwt;
  const [toastValue, setToastValue] = toast;

  const appRoutes = jwtValue ? protectedRoutes : routes;

  return (
    <Router>
      <Switch>
        {appRoutes.map((route) => (
          <Route exact key={route.path} path={route.path} component={route.component} />
        ))}
      </Switch>
      {toastValue && toastValue !== null && <Toast />}
    </Router>
  );
}
export default App;
