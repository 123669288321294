import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import NotificationCenter from './components/NotificationCenter';
import { AppContext } from '../../../contexts/AppContext';

import styles from './styles/Header.module.css';
import favicongreen3 from '../../../images/favicongreen3.png';

const Header = (props) => {
  const { jwt, authLevel } = useContext(AppContext);
  const [jwtValue, setJwtValue] = jwt;
  const [authLevelValue, setAuthLevelValue] = authLevel;
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [showSideMenu, setShowSideMenu] = useState(false);
  const show = () => {
    setShowSideMenu(true);
  };
  const hide = () => {
    setShowSideMenu(false);
  };

  function signout() {
    localStorage.clear();
    setJwtValue('');
  }

  const menuItems = props.menuItems.map((menuItem) => {
    if (menuItem.name === 'Admin Portal' && authLevelValue === 'student') return;
    let classes = [styles.header__menuItem];
    const action = menuItem.action === 'signout' ? signout : () => {};
    menuItem.active
      ? classes.push(styles['header__menuItem--active'])
      : classes.push(styles['header__menuItem--inactive']);
    if (menuItem.name === 'Login') {
      classes.push(styles['header__login-signout-button']);
    }
    classes = classes.join(' ');
    return (
      <li key={menuItem.name}>
        <Link className={classes} to={menuItem.path} onClick={() => action()}>
          {menuItem.name}
        </Link>
      </li>
    );
  });

  const loginSignOut = props.menuItems.map((menuItem) => {
    if (menuItem.name === 'Login' || menuItem.name === 'Sign out') {
      let classes = [styles['header__login-signout-button']];
      const action = menuItem.action === 'signout' ? signout : () => {};
      return (
        <div key={menuItem.name} style={{ display: 'flex' }}>
          {jwtValue && <NotificationCenter />}
          <Link className={classes} to={menuItem.path} onClick={() => action()}>
            {menuItem.name}
          </Link>
        </div>
      );
    } else return;
  });

  const sideMenuItems = props.menuItems.map((menuItem) => {
    if (menuItem.name != 'Login' && menuItem.name != 'Sign out') {
      if (menuItem.name === 'Admin Portal' && authLevelValue === 'student') return;
      let classes = [styles.sideMenu__item];
      menuItem.active
        ? classes.push(styles['sideMenu__item--active'])
        : classes.push(styles['sideMenu__item--inactive']);
      classes = classes.join(' ');
      return (
        <Link key={menuItem.name} className={classes} to={menuItem.path}>
          {menuItem.name}
        </Link>
      );
    } else return;
  });

  return isMobile ? (
    <div className={`${styles.header} ${styles.small}`}>
      {loginSignOut}
      <div className={showSideMenu ? `${styles.header__sideMenu} ${styles.active}` : `${styles.header__sideMenu}`}>
        <Link to="/">
          <img className={styles.sideMenu__logo} src={favicongreen3} />
        </Link>
        <FontAwesomeIcon className={styles['sideMenu__xMark']} icon={faXmark} onClick={hide} />
        <div className={styles['sideMenu__items']}>{sideMenuItems}</div>
      </div>
      <div
        className={showSideMenu ? `${styles.outsideSideMenu} ${styles.active}` : `${styles.outsideSideMenu}`}
        onClick={hide}
      ></div>
      <Link to="/">
        <img className={styles.header__logo} src={favicongreen3} />
      </Link>
      {props.doNotShowBars ? (
        ''
      ) : (
        <div className={styles['header__menuBars']}>
          <FontAwesomeIcon icon={faBars} onClick={show} />
        </div>
      )}
    </div>
  ) : (
    <div className={styles.header}>
      <Link to="/">
        <img className={styles.header__logo} src={favicongreen3} />
      </Link>
      <ul className={styles.header__menuItems}>
        {jwtValue && <NotificationCenter />}
        {menuItems}
      </ul>
    </div>
  );
};

export default Header;
