import FigmaDesk from '../../../images/Figma-green-desk.png';

import styles from '../styles/About.module.css';

function AboutUs() {
  return (
    <div className={styles['about-container']}>
      <div className={styles['about-info']}>
        <h3>
          Our Mission
          <span />
        </h3>
        <p>
          We are a K-12 centered online platform offering live classes to teach students how to code. Utilizing our
          dynamic lessons, and student to student approach, we effectively help each student get to where they want to
          be.
        </p>
      </div>
      <img src={FigmaDesk} alt="Student Learning" />
    </div>
  );
}

export default AboutUs;
