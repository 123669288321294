import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import globalNavigation from '../../config/globalNavigation';
import { AppContext } from '../../contexts/AppContext';
import Header from '../shared/header/Header';

import styles from './styles/AdminPortal.module.css';

function AdminOrgs(props) {
  const { jwt, authLevel } = useContext(AppContext);
  const [jwtValue, setJwtValue] = jwt;
  const [authLevelValue, setAuthLevelValue] = authLevel;
  const [orgs, setOrgs] = useState();

  const history = useHistory();

  useEffect(() => {
    if (authLevelValue === 'swcadmin') {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'x-access-token': jwtValue },
      };
      fetch(`${process.env.REACT_APP_SWC_API_URL}/org`, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            localStorage.removeItem('swc-access-token');
            localStorage.removeItem('swc-user-email');
            localStorage.removeItem('swc-user-authLevel');
            setJwtValue('');
            setAuthLevelValue('');
            history.replace('/');
          } else if (res.status != 200) history.replace('/');
          return res.json();
        })
        .then((data) => {
          setOrgs(data);
        })
        .catch((err) => console.log(err));
    } else
      history.replace({
        pathname: `/adminportal/myorg`,
        state: {
          orgName: 'My Organization',
        },
      });
  }, []);

  function displayOrgs() {
    if (!orgs) return;
    if (orgs.length === 0) return;
    if (orgs.length === 1)
      history.replace({ pathname: `/adminportal/${orgs[0]._id}`, state: { orgName: orgs[0].name } });
    if (props.location.state) {
      if (props.location.state.orgId && props.location.state.grpId && props.location.state.userId) {
        history.replace({
          pathname: `/adminportal/${props.location.state.orgId}`,
          state: {
            orgName: orgs.find((org) => org._id === props.location.state.orgId).name,
            grpId: props.location.state.grpId,
            userId: props.location.state.userId,
          },
        });
      } else if (props.location.state.orgId && props.location.state.grpId) {
        history.replace({
          pathname: `/adminportal/${props.location.state.orgId}`,
          state: {
            orgName: orgs.find((org) => org._id === props.location.state.orgId).name,
            grpId: props.location.state.grpId,
          },
        });
      } else {
        history.replace({
          pathname: `/adminportal/${props.location.state.orgId}`,
          state: {
            orgName: orgs.find((org) => org._id === props.location.state.orgId).name,
          },
        });
      }
    }
    return orgs.map((org) => {
      return (
        <div
          key={org._id}
          onClick={() => history.push({ pathname: `/adminportal/${org._id}`, state: { orgName: org.name } })}
          className={styles['item']}
        >
          <p>{org.name}</p>
        </div>
      );
    });
  }

  function displayTitle() {
    return (
      <div className={styles['nav--bar']}>
        <span className={styles['active']}>Admin Portal</span>
      </div>
    );
  }

  function displayMessage() {
    if (!orgs) return;
    if (orgs.length === 0)
      return (
        <div className={styles['empty--message']}>
          <p>There is no Organization yet</p>
        </div>
      );
  }

  return (
    <div className={styles['admin']}>
      <div className={styles['admin--content']}>
        <Header menuItems={globalNavigation.adminPortal} />
        {displayTitle()}
        {displayMessage()}
        <div className={styles['admin--list']}>{displayOrgs()}</div>
      </div>
    </div>
  );
}

export default AdminOrgs;
