const globalNavigation = {
    home: [
        {
            name: 'Login',
            path: '/login',
            active: false,
        },

        {
            name: 'Sign Up',
            path: '/signup',
            active: false,
        },

    ],
    login: [
        {
            name: 'Home',
            path: '/',
            active: false,
        },
        {
            name: 'Sign Up',
            path: '/signup',
            active: false,
        },
    ],
    groups: [
        {
            name: 'Admin Portal',
            path: '/adminportal',
            active: false,
        },
        {
            name: 'Reset Password',
            path: '/reset',
            active: false,
        },
        {
            name: 'Sign out',
            path: '/',
            active: false,
            action: 'signout',
        },
    ],
    reset: [
        {
            name: 'Admin Portal',
            path: '/adminportal',
            active: false,
        },
        {
            name: 'Reset Password',
            path: '/reset',
            active: true,
        },
        {
            name: 'Sign out',
            path: '/',
            active: false,
            action: 'signout',
        },
    ],
    adminPortal: [
        {
            name: 'Admin Portal',
            path: '/adminportal',
            active: true,
        },
        {
            name: 'Reset Password',
            path: '/reset',
            active: false,
        },
        {
            name: 'Sign out',
            path: '/',
            active: false,
            action: 'signout',
        },
    ],
};

export default globalNavigation;
