import React, { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp as faThumbsUpSolid } from '@fortawesome/free-solid-svg-icons';
import { faThumbsUp as faThumbsUpRegular } from '@fortawesome/free-regular-svg-icons';

import { AppContext } from '../../../contexts/AppContext';

import styles from '../styles/Comments.module.css';

function UpVoteButton({ hasUpvoted, upvoteCount, commentId, updateComment }) {
  const [hasUpvotedState, setHasUpvotedState] = useState(hasUpvoted);
  const [upvoteCountState, setUpvoteCountState] = useState(upvoteCount);

  const { jwt } = useContext(AppContext);
  const [jwtValue, setJwtValue] = jwt;

  function upvote() {
    const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json', 'x-access-token': jwtValue },
    };
    fetch(`${process.env.REACT_APP_SWC_API_URL}/comment/upvote/${commentId}`, requestOptions)
      .then((res) => {
        if (res.status === 200) {
          setHasUpvotedState((current) => !current);
          if (hasUpvotedState) {
            setUpvoteCountState((current) => current - 1);
            updateComment('addUpvote', commentId);
          } else {
            setUpvoteCountState((current) => current + 1);
            updateComment('removeUpvote', commentId);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    setUpvoteCountState(upvoteCount);
  }, [upvoteCount]);

  return (
    <div>
      {hasUpvotedState ? (
        <FontAwesomeIcon onClick={() => upvote()} className={styles.upvote} icon={faThumbsUpSolid} />
      ) : (
        <FontAwesomeIcon onClick={() => upvote()} className={styles.upvote} icon={faThumbsUpRegular} />
      )}
      <span className={styles['upvote--count']}>{upvoteCountState}</span>
    </div>
  );
}

export default UpVoteButton;
