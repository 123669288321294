import React, { useState, useRef, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import ReactPlayer from 'react-player';

import { AppContext } from '../../../contexts/AppContext';
import CommentSection from './CommentSection';

import styles from '../styles/Video.module.css';

function Video({ videoUrl }) {
  let { groupId, lessonId } = useParams();

  const [vidStats, setVidStats] = useState({});
  const [fullscreenMode, setFullscreenMode] = useState(false);

  const vidStatsRef = useRef(vidStats);

  const isMobile = useMediaQuery({ maxWidth: 1000 });

  const { jwt, groupDetails, authLevel } = useContext(AppContext);
  const [jwtValue, setJwtValue] = jwt;
  const [groupDetailsValue, setgroupDetailsValue] = groupDetails;
  const [authLevelValue, setAuthLevelValue] = authLevel;

  useEffect(() => {
    vidStatsRef.current = vidStats;
  }, [vidStats]);

  useEffect(() => {
    if (groupDetailsValue) {
      const updatedGroupDetails = groupDetailsValue.map((group) => {
        if (group._id === groupId) {
          const updatedLesson = group.lessons.map((lesson) => {
            if (lesson._id === lessonId) {
              const updatedPercWatched =
                Math.round(vidStatsRef.current.percentageWached * 100) > (lesson.percWatched ?? 0)
                  ? Math.round(vidStatsRef.current.percentageWached * 100)
                  : lesson.percWatched;
              return {
                ...lesson,
                percWatched: updatedPercWatched,
              };
            }
            return lesson;
          });
          return {
            ...group,
            lessons: updatedLesson,
          };
        }
        return group;
      });

      setgroupDetailsValue(updatedGroupDetails);
    }
  }, [vidStats])

  useEffect(() => {
    return () => {
      if (authLevelValue !== 'swcadmin') {
        const requestOptions = {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': jwtValue,
          },
          body: JSON.stringify({
            percWatched: Math.round(vidStatsRef.current.percentageWached * 100),
            currPercWatched: Math.round(vidStatsRef.current.percentageWached * 100),
            secondsWatched: vidStatsRef.current.secondsWatched,
            groupId: groupId,
            lessonId: lessonId,
            lessonType: 'lesson',
          }),
        };
        fetch(`${process.env.REACT_APP_SWC_API_URL}/user/updatestats`, requestOptions).then((response) => {
          if (response.status === 404) {
          }
          return;
        });
      }
    };
  }, [lessonId]);

  function handleProgress(state) {
    setVidStats((previousState) => ({
      ...previousState,
      percentageWached: state.played,
      secondsWatched: state.playedSeconds,
    }));
  }

  function handlePause() {}

  function handleEnded() {}

  function handleDuration(duration) {}

  return isMobile ? (
    <div className={styles['video']}>
      <ReactPlayer
        className={styles['video__player']}
        url={videoUrl}
        width="100%"
        height="fit-content"
        controls={true}
        pip={true}
        onProgress={handleProgress}
        onPause={handlePause}
        onEnded={handleEnded}
        onDuration={handleDuration}
        playsinline
      />
    </div>
  ) : (
    <div className={styles['video']}>
      <ReactPlayer
        className={styles['video__player']}
        url={videoUrl}
        width="100%"
        height="fit-content"
        controls={true}
        pip={true}
        onProgress={handleProgress}
        onPause={handlePause}
        onEnded={handleEnded}
        onDuration={handleDuration}
      />
      <CommentSection />
    </div>
  );
}

export default Video;
