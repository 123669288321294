import React, { useEffect, useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faXmark, faChevronRight, faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import { useMediaQuery } from 'react-responsive';

import globalNavigation from '../../config/globalNavigation';
import { AppContext } from '../../contexts/AppContext';
import Header from '../shared/header/Header';
import StatsTable from './components/StatsTable';

import styles from './styles/AdminPortal.module.css';

function AdminUsers(props) {
  const { jwt, authLevel } = useContext(AppContext);
  const [jwtValue, setJwtValue] = jwt;
  const [authLevelValue, setAuthLevelValue] = authLevel;
  const [orgadmins, setOrgadmins] = useState();
  const [teachers, setTeachers] = useState();
  const [students, setStudents] = useState();
  const [lessons, setLessons] = useState();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  let { orgId, grpId } = useParams();
  const history = useHistory();

  const [orgadminsExpanded, setOrgadminsExpanded] = useState(false);
  const [teachersExpanded, setTeachersExpanded] = useState(false);
  const [studentsExpanded, setStudentsExpanded] = useState(true);
  const changeAdminsState = () => {
    setOrgadminsExpanded(!orgadminsExpanded);
  };
  const changeTeachersState = () => {
    setTeachersExpanded(!teachersExpanded);
  };
  const changeStudentsState = () => {
    setStudentsExpanded(!studentsExpanded);
  };

  if (!props.location.state) {
    history.replace({
      pathname: `/adminportal`,
      state: {
        orgId: orgId,
        grpId: grpId,
      },
    });
  }

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'x-access-token': jwtValue },
    };
    fetch(`${process.env.REACT_APP_SWC_API_URL}/group/user?groupId=${grpId}`, requestOptions)
      .then((res) => {
        if (res.status === 401) {
          localStorage.removeItem('swc-access-token');
          localStorage.removeItem('swc-user-email');
          localStorage.removeItem('swc-user-authLevel');
          setJwtValue('');
          setAuthLevelValue('');
          history.replace('/');
        } else if (res.status != 200) history.replace('/');
        return res.json();
      })
      .then((data) => {
        setOrgadmins(data.filter((user) => user.authLevel === 'orgadmin'));
        setTeachers(data.filter((user) => user.authLevel === 'teacher'));
        setStudents(data.filter((user) => user.authLevel === 'student'));
      })
      .catch((err) => console.log(err));
    fetch(`${process.env.REACT_APP_SWC_API_URL}/group?groupId=${grpId}`, requestOptions)
      .then((res) => {
        if (res.status === 401) {
          localStorage.removeItem('swc-access-token');
          localStorage.removeItem('swc-user-email');
          localStorage.removeItem('swc-user-authLevel');
          setJwtValue('');
          setAuthLevelValue('');
          history.replace('/');
        } else if (res.status != 200) history.replace('/');
        return res.json();
      })
      .then((data) => {
        setLessons(data.course.lessons);
      })
      .catch((err) => console.log(err));
  }, []);

  function getColumns() {
    if (!lessons) return;
    if (props.location.state.userId) {
      history.replace({
        pathname: `/adminportal/${orgId}/${grpId}/${props.location.state.userId}`,
        state: {
          orgName: props.location.state.orgName,
          grpName: props.location.state.grpName,
          user: orgadmins.concat(teachers.concat(students)).find((user) => user._id === props.location.state.userId),
          lessons: lessons,
        },
      });
    }
    if (lessons.length === 0) return;
    let columns = [
      {
        Header: 'Name',
        Footer: (
          <div className={styles['average--container']}>
            <div className={styles['average1']}>
              Average&nbsp;
              <FontAwesomeIcon
                icon={faCircleQuestion}
                data-tip
                data-for="a1"
                className={styles['fa-circle-question']}
              />
            </div>
            <ReactTooltip id="a1" place="right" effect="solid" className={styles['tableTip']}>
              - The average percentage watched for a video lesson includes ONLY students who have STARTED the lesson
              <br />- The average score for a quiz includes ONLY students who have COMPLETED the quiz
            </ReactTooltip>
            <div className={styles['average2']}>
              Progress&nbsp;
              <FontAwesomeIcon
                icon={faCircleQuestion}
                data-tip
                data-for="a2"
                className={styles['fa-circle-question']}
              />
            </div>
            <ReactTooltip id="a2" place="right" effect="solid" className={styles['tableTip']}>
              - Progress reflects the extent to which the entire group has finished watching the video lesson
            </ReactTooltip>
          </div>
        ),
        accessor: 'name',
        sticky: 'left',
        disableSortBy: true,
        width: window.innerWidth < 767 ? 125 : 250,
      },
      {
        Header: 'Hidden',
        accessor: 'hidden',
      },
    ];
    lessons.forEach((lesson) => {
      columns.push({
        Header: lesson.name,
        Footer: (average) => {
          let notBlank = 0,
            lessonProgress;
          average.rows.forEach((row) => {
            if (row.values[lesson._id] != undefined) notBlank = notBlank + 1;
          });
          const total = average.rows.reduce(
            (sum, row) => (row.values[lesson._id] ? row.values[lesson._id] : 0) + sum,
            0
          );
          const lessonAverage = Math.round((total / notBlank) * 100) / 100;
          if (lesson.lessonType === 'lesson') lessonProgress = Math.round((total / average.rows.length) * 100) / 100;
          return (
            <div>
              <div className={styles['average1']}>{lessonAverage ? lessonAverage : ''}</div>
              <div>{lessonProgress ? lessonProgress : ''}</div>
            </div>
          );
        },
        accessor: lesson._id,
        width: window.innerWidth < 767 ? 75 : 150,
      });
    });
    return columns;
  }

  function getRows() {
    if (!students) return;
    if (students.length === 0) return;
    let rows = [];
    let currentRow = {};
    students.forEach((student) => {
      currentRow.name = (
        <a
          onClick={() =>
            history.push({
              pathname: `/adminportal/${orgId}/${grpId}/${student._id}`,
              state: {
                grpName: props.location.state.grpName,
                orgName: props.location.state.orgName,
                user: student,
                lessons: lessons,
              },
            })
          }
          style={{ cursor: 'pointer' }}
        >
          {`${student.fname} ${student.lname}`}
        </a>
      );
      currentRow.hidden = `${student.fname} ${student.lname}`;
      student.lessonStats.forEach((lessonStat) => {
        if (lessonStat.percWatched) currentRow[lessonStat.lesson] = lessonStat.percWatched;
        if (lessonStat.score && lessonStat.quizComplete) currentRow[lessonStat.lesson] = lessonStat.score;
      });
      rows.push(currentRow);
      currentRow = {};
    });
    return rows;
  }

  function displayTable() {
    if (!students || !lessons) return;
    if (students.length === 0 || lessons.length === 0) return;
    const columns = getColumns();
    const rows = getRows();
    if (columns.length === 2 || rows.length === 0) return;
    return (
      <div
        className={studentsExpanded ? `${styles.expandable} ${styles.active}` : `${styles.expandable}`}
        onClick={studentsExpanded === false ? changeStudentsState : () => {}}
      >
        <div className={styles['category--tooltip']}>
          <div className={styles['category']}>Students&nbsp;&nbsp;</div>
          {studentsExpanded ? (
            <div>
              <div className={styles['tableHelper']} data-tip data-for="tableTip">
                💡
              </div>
              <ReactTooltip id="tableTip" place="right" effect="solid" className={styles['tableTip']}>
                - Click name to see more information about the student
                <br />
                - Number of a lesson is the percentage watched
                <br />
                - Number of a quiz is the score
                <br />
              </ReactTooltip>
            </div>
          ) : (
            ''
          )}
        </div>

        <StatsTable columns={columns} rows={rows}></StatsTable>
        <button className={styles['expandable--toggle']} onClick={changeStudentsState}>
          <FontAwesomeIcon icon={faChevronDown} className={styles['fa-chevron-down']} />
          <FontAwesomeIcon icon={faXmark} className={styles['fa-times']} />
        </button>
      </div>
    );
  }

  function displayOrgadmins() {
    if (!orgadmins) return;
    if (orgadmins.length === 0) return;
    return (
      <div
        className={orgadminsExpanded ? `${styles.expandable} ${styles.active}` : `${styles.expandable}`}
        onClick={orgadminsExpanded === false ? changeAdminsState : () => {}}
      >
        <div className={styles['category']}>Admins</div>
        <div className={styles['admin--list']}>
          {orgadmins.map((orgadmin) => {
            return (
              <div
                key={orgadmin._id}
                onClick={() =>
                  history.push({
                    pathname: `/adminportal/${orgId}/${grpId}/${orgadmin._id}`,
                    state: {
                      grpName: props.location.state.grpName,
                      orgName: props.location.state.orgName,
                      user: orgadmin,
                      lessons: lessons,
                    },
                  })
                }
                className={styles['user']}
              >
                <p>{`${orgadmin.fname} ${orgadmin.lname}`}</p>
              </div>
            );
          })}
        </div>
        <button className={styles['expandable--toggle']} onClick={changeAdminsState}>
          <FontAwesomeIcon icon={faChevronDown} className={styles['fa-chevron-down']} />
          <FontAwesomeIcon icon={faXmark} className={styles['fa-times']} />
        </button>
      </div>
    );
  }

  function displayTeachers() {
    if (!teachers) return;
    if (teachers.length === 0) return;
    return (
      <div
        className={teachersExpanded ? `${styles.expandable} ${styles.active}` : `${styles.expandable}`}
        onClick={teachersExpanded === false ? changeTeachersState : () => {}}
      >
        <div className={styles['category']}>Teachers</div>
        <div className={styles['admin--list']}>
          {teachers.map((teacher) => {
            return (
              <div
                key={teacher._id}
                onClick={() =>
                  history.push({
                    pathname: `/adminportal/${orgId}/${grpId}/${teacher._id}`,
                    state: {
                      grpName: props.location.state.grpName,
                      orgName: props.location.state.orgName,
                      user: teacher,
                      lessons: lessons,
                    },
                  })
                }
                className={styles['user']}
              >
                <p>{`${teacher.fname} ${teacher.lname}`}</p>
              </div>
            );
          })}
        </div>
        <button className={styles['expandable--toggle']} onClick={changeTeachersState}>
          <FontAwesomeIcon icon={faChevronDown} className={styles['fa-chevron-down']} />
          <FontAwesomeIcon icon={faXmark} className={styles['fa-times']} />
        </button>
      </div>
    );
  }

  function displayTitle() {
    if (!props.location.state) return;
    return (
      <div className={styles['nav--bar']}>
        <span className={styles['not--active']} onClick={() => history.push(`/adminportal`)}>
          Admin Portal{' '}
        </span>
        <FontAwesomeIcon icon={faChevronRight} className={styles['fa-chevron-right']} />
        <span
          onClick={() =>
            history.push({
              pathname: `/adminportal/${orgId}`,
              state: { orgName: props.location.state.orgName },
            })
          }
          className={styles['not--active']}
        >
          {props.location.state.orgName}
        </span>
        <FontAwesomeIcon icon={faChevronRight} className={styles['fa-chevron-right']} />
        <span className={styles['active']}>{props.location.state.grpName}</span>
      </div>
    );
  }

  function displayMessage() {
    if (!orgadmins || !teachers || !students) return;
    if (orgadmins.length === 0 && teachers.length === 0 && students.length === 0)
      return (
        <div className={styles['empty--message']}>
          <p>There is no viewable user in this group yet</p>
        </div>
      );
  }

  return (
    <div className={styles['admin']}>
      <div className={styles['admin--content']}>
        <Header menuItems={globalNavigation.adminPortal} />
        {displayTitle()}
        {displayMessage()}
        {displayTable()}
        {displayTeachers()}
        {displayOrgadmins()}
      </div>
    </div>
  );
}

export default AdminUsers;
