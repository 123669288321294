import React, { useState } from 'react'
import { useAsyncDebounce } from 'react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons"

import styles from '../styles/AdminPortal.module.css'

export const GlobalFilter = ({ filter, setFilter }) => {
    const [value, setValue] = useState(filter)
    const onChange = useAsyncDebounce(value => {
        setFilter(value || undefined)
    }, 0)
    return (
        <div className={styles["search--bar"]}>
            <FontAwesomeIcon icon={faMagnifyingGlass} className={styles["fa-magnifying-glass"]}/>
            <input
                placeholder='Search'
                value={value || ''}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
            />
        </div>

    )
}