import React, { useState } from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import Arrow from './Arrow';

import styles from '../styles/Slides.module.css';

const Slides = () => {
  const youtubeLink = 'https://youtu.be/9VC_5Cxg1NM?si=CIrkHs2i7MhtMuH1'; /*YouTube link replaced here*/
  const youtubeID = youtubeLink.split('/').pop().split('?')[0];

  const slideProperties = {
    duration: 5000,
    transitionDuration: 500,
    nextArrow: Arrow(),
    prevArrow: Arrow('180deg'),
  };

  const [current, setCurrent] = useState(0);

  const handleSlideChange = (_, to) => {
    setCurrent(to);
  };

  const images = [
    {
      id: 'img1',
      url: 'compimg1.jpg',
      type: 'img',
      alt: 'Example',
      caption: 'Our interactive website makes it easy for students to apply what they have learned!',
    },
    {
      id: 'img2',
      url: 'compimg2.jpg',
      type: 'img',
      alt: 'Example',
      caption: 'Our interactive website makes it easy for students to apply what they have learned! 2',
    },
    {
      id: 'img3',
      url: 'compimg3.jpg',
      type: 'img',
      alt: 'Example',
      caption: 'Our interactive website makes it easy for students to apply what they have learned! 3',
    },
    {
      id: 'img4',
      url: `https://youtube.com/embed/${youtubeID}?autoplay=0}`,
      type: 'video',
      alt: 'Example',
      caption: 'Our interactive video makes it easy for students to apply what they have learned! 4',
    },
    {
      id: 'img5',
      url: '01-cat-stretching-feet.webp',
      type: 'img',
      alt: 'Example',
      caption: 'Our interactive website makes it easy for students to apply what they have learned! 4',
    },
  ];

  return (
    <div className={styles['slidesection']}>
      <h1 className={styles.header}>
        How We Teach
        <span />
      </h1>
      <Slide {...slideProperties} indicators onChange={handleSlideChange}>
        {images.map((media) => (
          <div className={styles['each-slide-effect']} key={media.id}>
            {media.type === 'video' ? (
              <iframe
                title="Youtube player"
                sandbox="allow-scripts allow-same-origin allow-presentation"
                src={media.url}
              />
            ) : (
              <img src={media.url} alt={media.alt} />
            )}
          </div>
        ))}
      </Slide>

      <p className={styles.teachtext}>{images[current || 0].caption}</p>
    </div>
  );
};

export default Slides;
