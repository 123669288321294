import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from '../styles/HomeIntroCard.module.css';

const HomeIntroCard = ({ icon, label }) => {
  return (
    <div className={styles['homeIntroCard']}>
      <div>
        <FontAwesomeIcon className={styles['homeIntroCard-icon']} icon={icon} />
      </div>
      <span>{label}</span>
    </div>
  );
};

export default HomeIntroCard;
