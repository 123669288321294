import React, { useState, useContext, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { profanity } from '@2toad/profanity';

import { AppContext } from '../../../contexts/AppContext';
import { formatCode } from '../utils/codeFormatter';

import styles from '../styles/Comments.module.css';

const LANGUAGES = ['html', 'css', 'javascript'];

function AddReplyForm({ parentCommentId, setShowReplyForm, updateComment, setShowReplyList }) {
  let { groupId, lessonId } = useParams();

  const [text, setText] = useState('');
  const [code, setCode] = useState('');
  const [formatErrors, setFormatErrros] = useState('');

  const textAreaRef = useRef(null);

  const { jwt, toast } = useContext(AppContext);
  const [jwtValue] = jwt;
  const [, setToastValue] = toast;
  const [isVisible, setIsVisible] = useState(false);
  const [language, setLanguage] = useState(LANGUAGES[0]);

  async function postComment(e) {
    e.preventDefault();

    if (text === '' && code === '') {
      return;
    }

    const newCommentModel = {
      isReply: true,
      isDeleted: false,
      lessonId: lessonId,
      groupId: groupId,
      content: [],
    };
    if (text !== '') {
      // if profanity exists, create a popup like when we go to delete comment, tell user they need to edit their comment or something like that.
      if (profanity.exists(text)) {
        return setToastValue({
          checkMark: false,
          message: 'Please do not use foul language! Try again!',
          time: 3,
        });
      }
    }

    const blocks = text.split('``');
    for (let block of blocks) {
      const commentContent = {
        type: 'text',
        content: block,
      };

      const regex = new RegExp(`^\`(${LANGUAGES.join('|')})\\n`);
      if (regex.test(block)) {
        const codeBlockStart = regex.exec(block).at(0);
        const blockLanguage = codeBlockStart.replace('`', '').replace('\n', '');

        commentContent.type = 'code';
        commentContent.language = blockLanguage;
        commentContent.content = block.substring(codeBlockStart.length);

        let { error, formatted } = await formatCode(commentContent.content, commentContent.language);

        if (error) {
          console.error('Error while formatting the code');
        } else {
          formatted = formatted
            .split('\n')
            .map((line) => line.trim())
            .join('\n');
          formatted = formatted.replaceAll('\n', '');
        }

        commentContent.content = formatted;
      }

      if (block.startsWith('`\n')) {
        commentContent.content = block.substring(2);
      }

      if (block.endsWith('\n')) {
        commentContent.content = commentContent.content.substring(0, block.length - 1);
      }

      if (block === '`' || block === '`\n' || block === '' || block === '\n') {
        continue;
      }

      newCommentModel.content.push(commentContent);
    }

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': jwtValue,
      },
      body: JSON.stringify({
        newComment: newCommentModel,
        parentCommentId: parentCommentId,
      }),
    };
    fetch(`${process.env.REACT_APP_SWC_API_URL}/comment`, requestOptions)
      .then((res) => {
        if (res.status === 201) {
          textAreaRef.current.style.height = 'auto';
          setText('');
          setShowReplyForm(false);
          setShowReplyList(true);
          return res.json();
        } else {
          return;
        }
      })
      .then((data) => updateComment('addReply', data, parentCommentId))
      .catch((error) => {
        console.log(error);
      });
  }

  function handleChange(e) {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
    setText(e.target.value);
  }

  function handleCodeChange({ target }) {
    target.style.height = 'auto';
    target.style.height = `${target.scrollHeight}px`;
    setCode(target.value);
  }

  function handleLanguageChange({ target }) {
    setLanguage(target.value);
  }

  function cancel() {
    setText('');
    setShowReplyForm(false);
  }

  function handleAddCodeSnippetForm() {
    setIsVisible(true);
    setFormatErrros('');
  }

  function handleCancelCode() {
    setIsVisible(false);
    setFormatErrros('');
    setCode('');
  }

  function handleAddCode() {
    const codeBlock = `\n\`\`\`${language}\n${code}\n\`\`\``;

    setText(text + codeBlock);
    setIsVisible(false);
    setCode('');
    textAreaRef.current.focus();
  }

  async function handleFormatCode() {
    setFormatErrros('');
    let { error, formatted } = await formatCode(code, language);

    if (error) {
      setFormatErrros(error.toString());
    }

    setCode(formatted);
  }

  const codeBlockCallbackRef = (node) => {
    if (node) {
      node.focus();
      node.style.height = 'auto';
      node.style.height = `${node.scrollHeight}px`;
    }
  };

  useEffect(() => {
    textAreaRef.current.style.height = 'inherit';
    textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
  }, [text]);

  return (
    <form className={styles['add--comment--form']} onSubmit={(e) => postComment(e)}>
      <button type="button" className={styles['add-code-button']} onClick={handleAddCodeSnippetForm}>
        Add code snippet
      </button>
      <textarea
        ref={textAreaRef}
        rows="1"
        placeholder="Add a reply..."
        className={styles['text-block-area']}
        onChange={(e) => handleChange(e)}
        value={text}
      />
      {isVisible && (
        <div className={styles['code-snippet']}>
          <label>Select language:</label>
          <select onChange={handleLanguageChange}>
            {LANGUAGES.map((language) => (
              <option key={language} value={language}>
                {language}
              </option>
            ))}
          </select>
          <div>
            <textarea
              ref={codeBlockCallbackRef}
              className={styles['code-block']}
              placeholder="Paste your code here"
              value={code}
              onChange={handleCodeChange}
            />
            <button type="button" className={styles['add-code-comment']} onClick={handleAddCode}>
              Add
            </button>
            <button type="button" className={styles['format-code-button']} onClick={handleFormatCode}>
              Format
            </button>
            <button type="button" className={styles['cancel-code-button']} onClick={handleCancelCode}>
              Cancel
            </button>
          </div>
          {formatErrors && <span className={styles['code-block-errors']}>{formatErrors}</span>}
        </div>
      )}
      <input
        type="submit"
        value="Reply"
        className={text != '' ? styles['submit--active'] : styles['submit--inactive']}
      ></input>
      <input type="button" value="Cancel" className={styles['cancel--active']} onClick={() => cancel()}></input>
    </form>
  );
}

export default AddReplyForm;
