import React, { useState, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import Header from '../shared/header/Header';
import globalNavigation from '../../config/globalNavigation';
import { AppContext } from '../../contexts/AppContext';

import styles from './styles/Login.module.css';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showError, setShowError] = useState(false);
  const [showPw, setShowPw] = useState(false);
  const toggleShowPw = () => {
    setShowPw(!showPw);
  };

  const emailRef = useRef(null);
  const pwRef = useRef(null);

  const { jwt, authLevel } = useContext(AppContext);
  const [, setJwtValue] = jwt;
  const [, setAuthLevelValue] = authLevel;

  const history = useHistory();

  function loginCall() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: email, password: password }),
    };

    fetch(`${process.env.REACT_APP_SWC_API_URL}/auth/login`, requestOptions)
      .then(async (response) => {
        if (response.ok) {
          let data = await response.json();

          localStorage.setItem('swc-access-token', JSON.stringify(data.accessToken));
          localStorage.setItem('swc-user-email', JSON.stringify(data.email));
          localStorage.setItem('swc-user-authLevel', JSON.stringify(data.authLevel));
          setJwtValue(data.accessToken);
          setAuthLevelValue(data.authLevel);

          history.push('/');
        } else if (response.status === 404 || response.status === 401) {
          setShowError(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleSubmit(event) {
    event.preventDefault();
    loginCall();
  }

  const handleGoBack = () => {
    history.push('/');
  };

  const handleFocus = (e) => {
    if (e.target.id === 'email') {
      emailRef.current.classList.add(styles['focus']);
    }
    if (e.target.id === 'pw') {
      pwRef.current.classList.add(styles['focus']);
    }
  };

  const handleBlur = (e) => {
    if (e.target.id === 'email') {
      emailRef.current.classList.remove(styles['focus']);
    }
    if (e.target.id === 'pw') {
      pwRef.current.classList.remove(styles['focus']);
    }
  };

  const doodleStyles = `
	@grid: 100x2 / 100%;
	@place: center;
	@size: calc(100% / @I * @i);
	transform: rotate(calc(@i * 5deg));
	border-radius: 30%;
	border: 1px solid hsla(
	    calc(10 + 4 * @i), 46%, 86%, @r.8
	);
    `;

  return (
    <div className={styles.login}>
      <css-doodle class={styles.doodle}>
        <style>{doodleStyles}</style>
      </css-doodle>
      <Header menuItems={globalNavigation.login} doNotShowBars={false} />
      <div className={styles['login-content']}>
        <form className={styles['form']} onSubmit={handleSubmit}>
          <h3>Students Who Code</h3>
          <h4>Ready to learn?</h4>
          <input
            id="email"
            ref={emailRef}
            className={styles.email}
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          <div ref={pwRef} className={styles['pw-holder']}>
            <input
              id="pw"
              type={showPw ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            <span onClick={() => toggleShowPw()}>
              {showPw ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
            </span>
          </div>
          {showError && <div className={styles['incorrent']}>Your login details are incorrect . Please try again.</div>}
          <button type="submit" className={styles['login-button']}>
            Login
          </button>
          <button type="button" className={styles['go-back-button']} onClick={handleGoBack}>
            Go Back
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
