import React from 'react';
import { useParams } from 'react-router-dom';

import Comment from './Comment';

import styles from '../styles/Comments.module.css';

function CommentList({ topLevelComments, updateComment }) {
  let { replyId } = useParams();

  return (
    <div className={styles['comment--list']}>
      {topLevelComments.map((comment) => {
        return <Comment key={comment._id} comment={comment} updateComment={updateComment} replyId={replyId}></Comment>;
      })}
    </div>
  );
}

export default CommentList;